import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss'],
})
export class MapPage {
	static url = 'map';
	constructor(
		private repository: RepositoryService,
		private navService: NavigationService
	) {}
	static navigate(router: Router, repository?: RepositoryService) {
		console.log(repository);
		if (repository) {
			repository.resetVenue();
			repository.address.next(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		return router.navigateByUrl(MapPage.url);
	}
	goHome() {
		this.navService.home();
	}
}
