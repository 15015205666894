import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { environment } from '../../../environments/environment';
import { getAvailability, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { AllergenInfoModalComponent, Nutrition } from '../allergen-info-modal/allergen-info-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-article-option-group-single',
	templateUrl: './article-option-group-single.component.html',
	styleUrls: ['article-option-group-single.component.scss'],
})
export class ArticleOptionGroupSingleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	@Output() openInfo = new EventEmitter<Article>();
	numberToCurrency = numberToCurrency;
	di = DisplayIdentifier;
	pricesMap = new Map<string, number>();
	hidePricesArray = ['Wähle dein Getränk - Medium', 'Wähle dein Getränk'];
	constructor(private modalCtrl: ModalController) {}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	selectedOptionIndex() {
		const indexSelection = this.selected.findIndex(selectedOption => selectedOption.group === this.optionGroup._id);
		if (indexSelection < 0) {
			return indexSelection;
		}
		return this.optionGroup.articles.findIndex(article => article._id === this.selected[indexSelection].article._id);
	}

	onSelectionChange(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}
	likeSotCalulation(option: Article) {
		// if (this.optionGroup.name.de.includes('Einzel oder Menü? SPLIT')) {
		if (this.optionGroup.displayIdentifiers.includes(DisplayIdentifier.menu)) {
			this.optionGroup.articles.forEach(it => {
				const price = (this.dependenciesRegardingOptionArticle(this.article, this.article.groups, it) ?? [])
					.map(option => {
						const arrayOfArticlesPriceInOption = option.articles.map(art => {
							return numberD(art.price);
						});
						const result = Math.min(...arrayOfArticlesPriceInOption) * option.requiredAmount;

						return result === Infinity || !result ? 0 : result;
					})
					.reduce((a, b) => a + b, 0);

				this.pricesMap.set(it._id, price);

				if (this.pricesMap.get(it._id) == null || this.pricesMap.get(it._id) == 0) {
					this.pricesMap.set(it._id, numberD(it.price) === Infinity ? 0 : numberD(it.price));
				}
			});
		}
	}
	price(option: Article): string {
		this.likeSotCalulation(option);
		if (this.pricesMap.has(option._id)) {
			const minValue = Math.min(...this.pricesMap.values());
			if (this.pricesMap.get(option._id) > minValue) {
				const diffPrice = this.pricesMap.get(option._id) - minValue;
				return '+ ' + numberToCurrency(diffPrice, this.currency);
			} else {
				return minValue ? numberToCurrency(minValue, this.currency) : '';
			}
		}
		if (this.optionGroup.displayIdentifiers.includes(DisplayIdentifier.sides)) {
			const minValue = Math.min(...this.optionGroup.articles.map(it => numberD(it.price)));
			const diffTo = numberD(option.price) - minValue;
			if (diffTo > 0) {
				return numberToCurrency(diffTo, this.currency);
			}
			return '';
		}
		let price = getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		return price > 0 ? numberToCurrency(price, this.currency) : '';
	}

	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0
		);
	}

	async openInfoClick(option: Article) {
		await AllergenInfoModalComponent.show(this.modalCtrl, option);
	}
	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article) {
		if (!article?.nutritions) {
			return;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
}
