import { environment } from './../../../environments/environment';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSlides, ModalController, PickerController, Platform } from '@ionic/angular';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import * as moment from 'moment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import validator from 'validator';
import { TosPage } from '../../pages/tos/tos.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { Router } from '@angular/router';
import { calculateGeoDistance, MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import { getSlots, sleep, numberToCurrency, getPrice, isVenueOpen, getSlotsByOrder } from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfoModalComponent } from '../info-modal/info-modal.component';

export const phoneCountries: { code: string; tel: string }[] = [
	{
		code: 'de',
		tel: '+49',
	},
	{
		code: 'au',
		tel: '+43',
	},
	{
		code: 'ua',
		tel: '+380',
	},
];
@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['checkout-modal.component.scss'],
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;

	name = 'name';
	email = 'email';
	phone = 'phone';
	street = 'street';
	city = 'city';
	postalCode = 'postalCode';
	orderAt = 'orderAt';
	number = 'number';
	note = 'note';
	licenseNumber = 'licenseNumber';
	vehicleBrand = 'vehicleBrand';
	vehicleColor = 'vehicleColor';
	preferredCountries = ['de', 'fr', 'us'];
	defaultCountry = 'de';
	nameError: string;
	emailError: string;
	phoneError: string;
	streetNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	hadPostalCode = false;
	hadCity = false;
	hadStreet = false;
	tip: number = 0;
	environment = environment;
	numberToCurrency = numberToCurrency;
	ou = OrderUtils;
	@ViewChild(IonContent, { static: false }) content;
	@ViewChild(IonSlides, { static: false }) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	isOverviewPage = false;
	slots: Moment[] = [];
	isVenueOpen = false;
	orderForm: FormGroup;
	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		public platform: Platform,
		private fb: FormBuilder
	) {
		super(repository);
		// if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
		// 	this.hadPostalCode = true;
		// }
		// if (this.order && this.order.preorder && this.order.preorder.postalCode && this.address.postalCode.length > 0) {
		// 	this.hadPostalCode = true;
		// }
		// if (this.address && this.address.city && this.address.city.length > 0) {
		// 	this.hadCity = true;
		// }
		// if (this.order && this.order.preorder && this.order.preorder.city && this.address.city.length > 0) {
		// 	this.hadCity = true;
		// }
		// if (this.address && this.address.street && this.address.street.length > 0) {
		// 	this.hadStreet = true;
		// }
		// if (this.order && this.order.preorder && this.order.preorder.street && this.address.street.length > 0) {
		// 	this.hadStreet = true;
		// }
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.beginCheckout(this.order);
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
		this.loadSlots();
		this.createOrderForm();
	}

	private createOrderForm() {
		const formControlNames: any = {};

		formControlNames[this.name] = ['', [Validators.required, Validators.pattern(/\w+\s\w+/)]];
		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				),
			],
		];
		formControlNames[this.phone] = ['', [Validators.required, this._customPhoneValidatorForLibrary]];

		formControlNames[this.orderAt] = ['', [Validators.required]];
		formControlNames[this.note] = ['', []];
		// formControlNames['test'] = [
		// 	'',
		// 	[Validators.required, this._customPhoneValidatorForLibrary]
		// ];
		if (this.order.preorder.type === PreorderType.DELIVERY) {
			formControlNames[this.street] = ['', [Validators.required]];
			formControlNames[this.city] = ['', [Validators.required]];
			formControlNames[this.postalCode] = ['', [Validators.required]];
			formControlNames[this.number] = ['', [Validators.required]];
		}
		if (this.order.preorder.type === PreorderType.PARK_COLLECT) {
			formControlNames[this.licenseNumber] = ['', [Validators.required]];
			formControlNames[this.vehicleBrand] = ['', [Validators.required]];
			formControlNames[this.vehicleColor] = ['', [Validators.required]];
		}
		this.orderForm = this.fb.group(formControlNames);
		this.reloadAddress();
		this.checkCustomer();
		this.checkPrevOrder();
		this.orderForm.valueChanges.subscribe(v => console.log(v));
	}
	getFormControlValue(controlName?: string) {
		if (controlName) {
			return this.orderForm.get(controlName)?.value;
		}
		return '';
	}
	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm.get(controlName)?.hasError('required') && this.orderForm.get(controlName)?.touched;
		}
		return this.orderForm?.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm.get(controlName)?.errors && !this.orderForm.get(controlName)?.hasError('required');
		}
		return this.orderForm.invalid;
	}

	getErrorsForm(controlName) {
		if (controlName) {
			return this.orderForm.get('phone').errors;
		}

		return null;
	}

	static async show(modalCtrl: ModalController, order: Order, tip: number) {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			componentProps: {
				tip,
			},
			cssClass: 'checkout-modal-view background-modal',
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		return await modal.onDidDismiss();
	}

	onVenue() {
		this.isVenueOpen = isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order && this.order.preorder && this.order.preorder.postalCode && this.order.preorder.postalCode.length > 0;
		const streetInOrder = this.order && this.order.preorder && this.order.preorder.street && this.order.preorder.street.length > 0;
		const cityInOrder = this.order && this.order.preorder && this.order.preorder.city && this.order.preorder.city.length > 0;

		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.name = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName = this.repository._previousPreorder.companyName;
				}
				if (!this.order.preorder.floor) {
					this.order.preorder.floor = this.repository._previousPreorder.floor;
				}
				if (!this.order.preorder.subcard) {
					this.order.preorder.subcard = this.repository._previousPreorder.subcard;
				}
			}
		}
		this.hadPostalCode = this.hadPostalCode || postalCodeInOrder;
		this.hadCity = this.hadCity || cityInOrder;
		this.hadStreet = this.hadStreet || streetInOrder;

		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			if (this.customer.city && this.customer.city.length > 0) {
				this.hadCity = true;
			}
			if (this.customer.street && this.customer.street.length > 0) {
				this.hadStreet = true;
			}
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	async close() {
		await this.updateForm();
		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
			tip: this.tip,
		};
		// if (!) {
		// 	await sleep(100);
		// 	const firstErrorElement: HTMLElement = [...this.content.el.children].find(el => {
		// 		return el.className.split(' ').findIndex(clazz => clazz === 'input-error') >= 0;
		// 	});
		// 	if (firstErrorElement !== undefined) {
		// 		await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
		// 	}
		// 	return;
		// }
		this.repository.previousPreorder.next(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}
	async updateForm() {
		this.order.preorder.name = this.getFormControlValue('name');
		this.order.preorder.phone = this.getFormControlValue('phone')?.internationalNumber;
		this.order.preorder.email = this.getFormControlValue('email');
		this.order.preorder.note = this.getFormControlValue('note');
		this.order.orderAt = this.getFormControlValue('orderAt');
		if (this.order.preorder.type === PreorderType.DELIVERY) {
			this.order.preorder.city = this.getFormControlValue('city');
			this.order.preorder.postalCode = this.getFormControlValue('postalCode');
			this.order.preorder.number = this.getFormControlValue('number');
			this.order.preorder.street = this.getFormControlValue('street');
		}
		if (this.order.preorder.type === PreorderType.PARK_COLLECT) {
			this.order.preorder.licenseNumber = this.getFormControlValue('licenseNumber');
			this.order.preorder.vehicleBrand = this.getFormControlValue('vehicleBrand');
			this.order.preorder.vehicleColor = this.getFormControlValue('vehicleColor');
		}
		this.repository.order.next(this.order);
	}
	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}
	private _customPhoneValidatorForLibrary(control: AbstractControl) {
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value?.internationalNumber);
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return { invalidPhone: true };
			}
		} catch (e) {
			return { invalidCountryCode: true };
		}
	}
	// private _customPhoneValidator(control: AbstractControl) {
	// 	const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
	// 	try {
	// 		const phoneNumber = phoneUtil.parse(control?.value);
	// 		if (
	// 			phoneUtil.isValidNumber(phoneNumber) &&
	// 			phoneUtil.isPossibleNumber(phoneNumber)
	// 		) {
	// 			return null;
	// 		} else {
	// 			return {invalidPhone: true};
	// 		}
	// 	} catch (e) {
	// 		return {invalidCountryCode: true};
	// 	}
	// }
	async submitFormCustomValidator() {
		let controlStreetValue = this.getFormControlValue('street').trim();
		let controlHouseNumberValue = this.getFormControlValue('number').trim();
		let controlPostalCodeValue = this.getFormControlValue('postalCode').trim();
		let controlCityValue = this.getFormControlValue('city').trim();
		if (
			OrderUtils.isDelivery(this.order) &&
			(controlStreetValue !== this.address.street ||
				controlPostalCodeValue !== this.address.postalCode ||
				controlCityValue !== this.address.city ||
				controlHouseNumberValue !== this.address.number)
		) {
			try {
				const pred = controlStreetValue + ' ' + controlHouseNumberValue + ', ' + controlCityValue + ' ' + controlPostalCodeValue;
				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(address, true);
				if (addressValidationResult) {
					// console.log(addressValidationResult);
					await InfoModalComponent.show(this.modalCtrl, {
						info: this.translate.instant('map_page.venue_does_not_delivery_to_address'),
						closeButton: this.translate.instant('add_points.close'),
					});
					return false;
				}
				const delivers =
					(this.venue.deliveryByRadius &&
						calculateGeoDistance(
							address.lat,
							address.lng,
							this.venue.location.coordinates[1],
							this.venue.location.coordinates[0]
						) <= this.venue.deliveryRadius) ||
					(this.venue.isPostalDelivery && this.venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					this.orderForm.controls['street'].setErrors({ street_delivery: true });

					this.snackbarCtrl.open(this.translate.instant('errors.street_delivery'), null, {
						duration: 2000,
					});
					return false;
				}
				this.repository.address.next(address);
				this.orderForm.patchValue({
					street: this.address.street,
					number: this.address.number,
					postalCode: this.address.postalCode,
					city: this.address.city,
				});
				return true;
			} catch (e) {
				console.log(e);
				this.orderForm.controls['street'].setErrors({ street_not_found: true });
				this.snackbarCtrl.open(this.translate.instant('errors.street_not_found'), null, {
					duration: 2000,
				});
				return false;
			}
		} else {
			return true;
		}
	}

	reloadAddress() {
		if (this.order && this.order.preorder && this.address && this.order.preorder.type === PreorderType.DELIVERY && this.orderForm) {
			this.orderForm.patchValue({
				city: this.address?.city ?? '',
				street: this.address?.street ?? '',
				postalCode: this.address?.postalCode && this.address?.postalCode?.length > 0 ? this.address.postalCode : '',
				country: this.address?.country,
				number: this.address?.number,
			});
		}
	}

	async toOverview() {
		const result = this.orderForm.valid;
		if (!result) {
			this.isOverviewPage = false;
			return;
		}
		await this.slides.update();
		await this.slides.slideNext();
		console.log('Slide next');
		this.isOverviewPage = true;
	}

	async editPersonalData() {
		await this.slides.slideTo(0);
		this.isOverviewPage = false;
	}

	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		if (!slot) {
			return this.translate.instant('checkout_modal.choose_slot');
		}
		if (!moment.isMoment(slot)) {
			slot = moment(slot);
		}
		const now = moment();
		const sameDay = slot.dayOfYear() === now.dayOfYear() && slot.year() === now.year();
		if (sameDay) {
			return slot.format('HH:mm');
		} else {
			return slot.format('HH:mm / DD.MM.yyyy');
		}
	}

	getPlaceholder(key: string): string {
		return `${this.translate.instant(key)} ${this.translate.instant('checkout_modal.type')}`;
	}

	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = getPrice(articleOption.article, OrderType.PREORDER, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && articleGroup.isPromo) {
			return 0;
		}
		return price * articleOption.quantity;
	}

	hiddenInCart(article: Article, group: ArticleOption, articleGroup: ArticleGroup): boolean {
		const foundgroupMenu = articleGroup.article.groups.find(it => it._id === group.group);

		return !foundgroupMenu.visible || (article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined);
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	async onButtonClick() {
		this.orderForm.markAllAsTouched();

		if (this.order?.preorder?.type === PreorderType.DELIVERY) {
			if (!(await this.submitFormCustomValidator())) {
				return;
			}
		}
		this.updateForm();
		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}

	async loadSlots() {
		this.slots = [];
		this.order.orderAt = null;
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			const allSlots = await getSlotsByOrder(this.venue, this.order);

			this.slots = [...new Set(allSlots.map(item => moment(item.time).format('X')))].map(it => moment.unix(Number(it)));
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000,
				});
				return;
			}
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000,
			});
			console.error(e);
		}
		this.loading = false;
	}
	checkCustomer() {
		if (this.customer) {
			if (this.customer?.name) {
				this.orderForm.patchValue({
					name: this.customer?.name ?? '',
				});
			}
			if (this.customer?.email) {
				this.orderForm.patchValue({
					email: this.customer?.email ?? '',
				});
			}
			if (this.customer?.phone) {
				this.orderForm.patchValue({
					phone: {
						internationalNumber: this.repository._previousPreorder?.phone ?? '',
						dialoCode: '+49',
						isoCode: 'de',
						nationalNumber: this.repository._previousPreorder?.phone.trim().replace('+49', ''),
					},
				});
			}
		}
	}
	checkPrevOrder() {
		if (this.order && this.order.preorder) {
			if (this.repository._order) {
				if (this.repository._order?.preorder?.name) {
					this.orderForm.patchValue({
						name: this.repository._order?.preorder?.name ?? '',
					});
				}
				if (this.repository._order?.preorder?.email) {
					this.orderForm.patchValue({
						email: this.repository._order?.preorder?.email ?? '',
					});
				}
				if (this.repository._order?.preorder?.phone) {
					this.orderForm.patchValue({
						phone: {
							internationalNumber: this.repository._order?.preorder?.phone ?? '',
							dialoCode: '+49',
							isoCode: 'de',
							nationalNumber: this.repository._order?.preorder?.phone.trim().replace('+49', ''),
						},
					});
				}
			}
		}
	}
	async changeOrder() {
		await this.updateForm();
		this.dismiss();
	}
}
