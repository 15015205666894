import { Component, Input } from '@angular/core';
import { OrderStatus } from '../../../smoothr-web-app-core/enums/OrderStatus';
import * as moment from 'moment';
import { numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import { OrderPage } from '../../pages/order/order.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-my-order',
	templateUrl: './my-order.component.html',
	styleUrls: ['my-order.component.scss'],
})
export class MyOrderComponent {
	@Input() order: Order;

	showQr = false;
	moment = moment;

	os = OrderStatus;
	pt = PreorderType;
	numberToCurrency = numberToCurrency;
	OrderUtils = OrderUtils;

	loading = false;

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService
	) {}

	async reorder() {
		if (this.order?.preorder) {
			this.loading = true;
			try {
				this.order.coupon = [];
				this.order.orderedArticles = this.order.orderedArticles.filter(it => !it?.freeArticle || !it?.isPromo);
				await this.repository.reorder(this.translate, this.order);
				await OrderPage.navigate(this.router);
			} catch (e) {
				console.error(e);
				this.snackbarCtrl.open(e.message ? e.message : this.translate.instant('my_order.reorder_error'), null, {
					duration: 2000,
				});
			}
			this.loading = false;
		}
	}
}
