import { Component, EventEmitter, Input, Output } from '@angular/core';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { WorkingHoursModalComponent } from '../working-hours-modal/working-hours-modal.component';
import { ModalController } from '@ionic/angular';
import { PreorderType, PreorderTypeArray } from '../../../smoothr-web-app-core/enums/PreorderType';
import moment, { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { getSlots, isVenueOpen, sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { Api } from 'src/smoothr-web-app-core/api/api';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-venue-suggestion',
	templateUrl: './venue-suggestion.component.html',
	styleUrls: ['venue-suggestion.component.scss'],
})
export class VenueSuggestionComponent {
	environment = environment;
	@Input() type: PreorderType;
	@Output() venuePick = new EventEmitter<{ venue: Venue; type: PreorderType }>();
	isVenueOpen = false;
	pt = PreorderType;
	@Input()
	overridePostalDelivery = false;
	acceptsDelivery = false;
	firstDeliveryTime: string = null;
	acceptsInside = false;
	firstInsideTime: string = null;
	acceptsTakeaway = false;
	firstTakeAwayTime: string = null;
	acceptsParkCollect = false;
	customer: Customer = null;
	@Input()
	selected = false;
	ptArray = PreorderTypeArray.filter(it => {
		return it !== PreorderType.INSIDE;
	});
	@Input() isShopView = false;
	selectPreorderTypeAuto: PreorderType = null;
	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService,
		private repository: RepositoryService,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar
	) {
		this.selectPreorderTypeAuto = null;
		this.route.queryParams.subscribe(params => {
			if (params && params?.preorderType) {
				this.selectPreorderTypeAuto = params.preorderType;
			}
		});
		this.customer = this.repository.customer;
	}

	private _venue: Venue;

	get venue(): Venue {
		return this._venue;
	}
	loading = false;
	@Input()
	set venue(venue: Venue) {
		this._venue = venue;
		this.acceptsDelivery = false;
		this.acceptsTakeaway = false;
		this.acceptsInside = false;
		this.firstDeliveryTime = null;
		this.firstTakeAwayTime = null;
		this.firstInsideTime = null;
		this.isVenueOpen = false;
		if (venue === undefined || venue === null) {
			return;
		}

		this.isVenueOpen = isVenueOpen(venue);
		getSlots(venue, OrderType.PREORDER, PreorderType.TAKE_AWAY, null, 0, false, 2).then(slots => {
			this.acceptsTakeaway = slots.length > 0 && venueAcceptsOrders(venue, PreorderType.TAKE_AWAY);
			if (this.acceptsTakeaway) {
				this.firstTakeAwayTime = this.timeToText(venue, moment(slots[0].time));
			}
		});
		if (this.venue.deliveryEnabled) {
			getSlots(venue, OrderType.PREORDER, PreorderType.DELIVERY, null, 0, false, 2).then(slots => {
				this.acceptsDelivery = slots.length > 0 && venueAcceptsOrders(venue, PreorderType.DELIVERY, this.overridePostalDelivery);
				if (this.acceptsDelivery) {
					this.firstDeliveryTime = this.timeToText(venue, moment(slots[0].time));
				}
			});
		}
	}

	selectVenue(venue: Venue, type: PreorderType) {
		console.log('SELECT VENUE', venue);
		if (!this.accepts(type)) {
			if (type)
				this.snackBar.open(
					`Sorry, order type ${this.translate.instant('home_page.' + type)} is not avaible in shop ${venue.name} at the moment..`
				);
			return;
		}
		this.venuePick.emit({ venue, type });
	}

	accepts(type: PreorderType): boolean {
		switch (type) {
			case PreorderType.DELIVERY:
				return this.acceptsDelivery;
			case PreorderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			case PreorderType.INSIDE:
				return this.acceptsInside;
			case PreorderType.PARK_COLLECT:
				return this.acceptsTakeaway && this.venue.preorderParkCollect;
			default:
				return false;
		}
	}

	async openModal(venue: Venue) {
		this.loading = true;
		await WorkingHoursModalComponent.show(this.modalCtrl, venue, this.type);
		await sleep(250);
		this.loading = false;
	}

	timeToText(venue: Venue, time: Moment | null) {
		if (time && venue.slot) {
			const now = moment().add(
				Math.abs((moment().minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const roundedTime = time.add(
				Math.abs((time.minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const relevantTime = now.isAfter(roundedTime) ? now : roundedTime;
			const isToday = now.dayOfYear() === relevantTime.dayOfYear() && now.year() === relevantTime.year();
			return (isToday ? '' : this.translate.instant('venue_suggestion.tomorrow') + ' ') + relevantTime.format('HH:mm');
		} else {
			return null;
		}
	}
	isVenueOpenFunc() {
		return this.isVenueOpen ? this.translate.instant('venue_suggestion.open') : this.translate.instant('venue_suggestion.close');
	}
	// async toggleFavourite(venueId: string) {
	// 	if (this.checkIfFavourite(venueId)) {
	// 		const response = await Api.removeFavouritesVenueToUser(
	// 			this.repository.customer._id,
	// 			[venueId]
	// 		);
	// 		this.repository._customerAuth.customer = response;
	// 		this.repository.customerAuth.next(this.repository._customerAuth);
	// 		// this.repository.deleteFavourite(this.venue._id);
	// 	} else {
	// 		const response = await Api.addFavouritesVenueToUser(
	// 			this.repository.customer._id,
	// 			[venueId]
	// 		);
	// 		this.repository._customerAuth.customer = response;
	// 		this.repository.customerAuth.next(this.repository._customerAuth);
	// 		// this.repository.addFavourite(this._venue._id);
	// 	}
	// }
	// checkIfFavourite(venueId: string) {
	// 	return (
	// 		this.repository._customerAuth.customer &&
	// 		!this.repository._customerAuth.customer?.isAnonymous &&
	// 		this.repository.customer.favoriteVenues.length > 0 &&
	// 		this.repository.customer.favoriteVenues.find(it => it === this.venue._id)
	// 	);
	// }
}
