import { Component, EventEmitter, Input, Output } from '@angular/core';
import Article from 'src/smoothr-web-app-core/models/Article';
import Customer from 'src/smoothr-web-app-core/models/Customer';

@Component({
	selector: 'app-loyalty-item',
	templateUrl: './loyalty-item.component.html',
	styleUrls: ['./loyalty-item.component.scss'],
})
export class LoyaltyItemComponent {
	constructor() {}
	@Input() ashPointItems: Article[] = [];
	@Output() buttonClick = new EventEmitter<Article>();
	@Input() customer: Customer;

	selectItem(item: Article) {
		this.buttonClick.emit(item);
	}
	ifUserCanOrderArticle(article: Article) {
		return article.isPiggyLoyalty && article.piggyPointsNeeded > 0 && !this.customer.isAnonymous
			? (this.customer.piggyPoints - this.customer?.usedPiggyPoints ?? 0) - article.piggyPointsNeeded
			: 0;
	}
}
