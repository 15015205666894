import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-select-table-modal',
	templateUrl: './select-table-modal.component.html',
	styleUrls: ['./select-table-modal.component.scss'],
})
export class SelectTableModalComponent implements OnInit {
	order: Order;
	environment = environment;
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, order: Order) {
		const modal = await modalCtrl.create({
			component: SelectTableModalComponent,
			cssClass: 'only-next-day-modal auto-height',
			componentProps: {
				order,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		await modal.onDidDismiss();
	}

	ngOnInit() {}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}
}
