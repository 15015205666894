import { Component, EventEmitter, Input, Output } from '@angular/core';
import Article from 'src/smoothr-web-app-core/models/Article';
import { Coupon } from 'src/smoothr-web-app-core/models/Coupon';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-plus-one-free-article',
	templateUrl: './plus-one-free-article.component.html',
	styleUrls: ['./plus-one-free-article.component.scss'],
})
export class PlusOneFreeItemComponent {
	constructor() {}
	@Input() articleArray: Article[] = [];
	@Output() buttonClick = new EventEmitter<Article>();
	@Input() customerData: Customer;
	@Input() order: Order;

	selectItem(item: Article) {
		this.buttonClick.emit(item);
	}
	checkIfInOrder(item: Article): boolean {
		return !!(this.order?.orderedArticles || []).find(it => it?.article._id === item._id);
	}
}
