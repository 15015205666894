import { Component } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { HomePage } from '../../pages/home/home.page';
import { environment } from '../../../environments/environment';
import { MapPage } from 'src/app/pages/map/map.page';

@Component({
	selector: 'app-home-popover',
	templateUrl: './home-popover.component.html',
	styleUrls: ['home-popover.component.scss'],
})
export class HomePopoverComponent {
	environment = environment;

	constructor(
		public repository: RepositoryService,
		private router: Router,
		private popoverCtrl: PopoverController
	) {}

	async change() {
		await this.popoverCtrl.dismiss();
		await MapPage.navigate(this.router, this.repository);
	}

	async dismiss() {
		await this.popoverCtrl.dismiss();
	}
}
