import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { ToOrderButtonComponent } from '../../components/to-order-button/to-order-button.component';
import { IonSearchbar, ModalController, ViewDidEnter } from '@ionic/angular';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import { AppComponent } from '../../app.component';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { SelectVenueModalComponent } from '../../components/select-venue-modal/select-venue-modal.component';
import {
	axiosErrorToMessage,
	getAvailability,
	getSlotsByOrder,
	isVenueOpen,
	numberD,
	numberToCurrency,
	sleep,
} from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { OnlyNextDayModalComponent } from '../../components/only-next-day-modal/only-next-day-modal.component';
import { NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { ShowRecomendationArticlesComponent } from 'src/app/components/show-recomendation-articles/show-recomendation-articles.component';
import Order from 'src/smoothr-web-app-core/models/Order';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { InfoModalComponent } from 'src/app/components/info-modal/info-modal.component';

enum toggleTags {
	All = 'all',
	Vegeterian = 'veggie',
}
@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss'],
})
export class MenuPage extends RepositoryDirective implements OnInit, ViewDidEnter {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, { static: false }) toOrderButton;
	@ViewChild('categoryList', { static: false }) categoryList;
	@ViewChild('articleContent', { static: false }) articleList;
	@ViewChild(IonSearchbar, { static: false }) searchBar;

	selectedCategory: ArticleCategory = null;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	isValid = false;
	orderUtils = OrderUtils;
	differenceToMvo: number;
	preorderType = PreorderType;
	attempt = 1;
	venueOpen = true;
	venuePanic = false;
	environment = environment;
	searchBarOpen: boolean = false;
	toggleTagsEnum = toggleTags;
	selectedToggleTag = toggleTags.All;
	showDialog = false;
	isOpenOrdersBlock = false;

	numberToCurrency = numberToCurrency;
	public previousOrders: Order[] = [];
	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private router: Router,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	get index(): number {
		return this.categories.findIndex(category => category._id === this.selectedCategory._id);
	}
	get preorderTypeOrder(): PreorderType {
		return this.order?.preorder?.type;
	}
	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	async ngOnInit() {
		super.ngOnInit();

		switch (window.location.pathname) {
			case '/' + NAVIGATIONURLS.order():
				break;
			case '/' + NAVIGATIONURLS.paymentCancel():
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case '/' + NAVIGATIONURLS.paymentFail():
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}
		await this.loadVenue();
		this.route.queryParams.subscribe(params => {
			if (params && params.itemId) {
				const article = ([].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
					return it._id === params.itemId;
				});
				console.log(article);
				if (article) {
					this.openModal(article, true);
				}
			}
			if (params && params?.tag) {
				const categoryIndex = this.venue.articleCategories.findIndex(
					category => category?.tags && category?.tags?.find(it => it?.identifier === 'specials')
				);

				this.loadToSpecificTag(categoryIndex);
			}
		});
		await this.loadOrders();
	}
	ionViewDidEnter(): void {
		this.categories = [];
		this.reloadPanicAndOpening();
		this.reloadCategories();
	}
	ngAfterViewInit() {}
	async loadToSpecificTag(index: number) {
		await sleep(1500);
		await this.scrollCategoryListTo(index);
		await this.scrollArticleListTo(index);
	}
	async loadVenue() {
		this.loading = true;
		try {
			if (!this.venue) {
				this.repository.venue.next(await this.repository.getVenue(this.order?.venue ?? environment.customerGroup + '_master'));
			}
		} catch (e) {
			console.error(e);
			this.loading = false;
		}
		await this.reloadPanicAndOpening();
		await this.reloadCategories();
		this.loading = false;
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		this.scrollEvents = false;
		this.selectedCategory = this.categories[index];
		await this.scrollArticleListTo(index);
		await this.scrollCategoryListTo(index);
		this.scrollEvents = true;
		return;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === 0 && categoryIndex === -1 && this.categories[0]) {
			this.selectedCategory = this.categories[0];
			await this.scrollCategoryListTo(categoryIndex);
			return;
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategory = this.categories[categoryIndex];
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	async openModal(item: Article, addWithAshPoints: boolean = false) {
		if (!this.order) {
			await OnlyNextDayModalComponent.show(this.modalCtrl);
			const result = await SelectVenueModalComponent.show(this.modalCtrl);
			if (result === undefined) {
				this.loading = false;
				return;
			}
			const venue = await this.repository.getVenue(result.venue._id);

			if (!result.preorderType && !this.order?.table && this.order?.preorder.type !== PreorderType.INSIDE) {
				return;
			} else {
				this.repository.createOrder(result.venue, result.address, OrderType.PREORDER, result.preorderType, null);
				const allArticles = [];
				venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));
				const articleInVenue = allArticles.find(
					art => art._id === item._id || (art.masterId === item.masterId && item.masterId) || art.masterId === item._id
				);
				this.loading = false;
				if (!articleInVenue) {
					return;
				}
				item = articleInVenue;
			}
		}
		if (addWithAshPoints) {
			item.isPiggyLoyalty;
			await this.openSelectedProduct(item, item.isPiggyLoyalty);
		} else {
			await this.openSelectedProduct(item);
		}
	}

	async onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
		if (!this.searchTerm) {
			this.selectedCategory = this.categories[0];
			await this.scrollCategoryListTo(0);
			await this.scrollArticleListTo(0);
		}
	}

	async openSelectedProduct(item: Article, adddWithAshPoints: boolean = false) {
		// item.defaults = [];
		item.defaults = item.defaults || [];

		const res = await ModalInfoComponent.show(this.modalCtrl, item, this.order.preorder?.type, adddWithAshPoints);
		if (res) {
			OrderUtils.addToOrder(this.order, res, this.analytics, adddWithAshPoints);

			this.repository.order.next(this.order);
			if (this.customer && !this.customer.isAnonymous) {
				this.repository.addPointsToArticle();
			}
		}
	}
	reloadCategories() {
		if (!this.venue) {
			this.categories = [];
			return;
		}

		sleep(200);
		const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
		const result = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = getAvailability(article, OrderType.PREORDER, this.preorderTypeOrder);

					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase(),
						];
						return available && keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
					}
					return available;
				});

				if (this.selectedToggleTag !== toggleTags.All) {
					cat.articles = cat.articles.filter(article => {
						if (this.selectedToggleTag === toggleTags.Vegeterian) {
							return article.compound?.features?.vegetarian || article.compound?.features?.vegan;
						} else {
							return false;
						}
					});
				}

				return cat;
			})
			.filter(category => category.articles.length > 0);
		if (result.length > 0) {
			this.categories = result;
		}
		return;
	}

	validate() {
		if (!this.venue || !this.order) {
			this.isValid = false;
			return;
		}
		const mov = this.order.preorder?.type === PreorderType.DELIVERY ? numberD(this.venue.movDelivery) : 0;
		const orderValue = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder?.type,
			this.order.terminalorder?.type
		);
		this.differenceToMvo = orderValue - mov;
		this.isValid = this.differenceToMvo >= 0;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.loadVenue();
			return;
		}
		console.log('ON VENUE', this.venue.name);
		this.reloadCategories();
		this.reloadPanicAndOpening();
		this.selectedCategory = this.venue && this.categories.length > 0 ? this.categories[0] : null;
		this.validate();
	}

	async reloadPanicAndOpening() {
		this.loading = true;
		if (!this.venue) {
			this.venueOpen = false;
			this.venuePanic = false;
			this.loading = false;
			return;
		}
		if ((this.order?.type ?? OrderType.STANDARD) === OrderType.STANDARD) {
			this.venueOpen = isVenueOpen(this.venue);
		} else {
			this.venueOpen = (await getSlotsByOrder(this.venue, this.order, 0, false, 2)).length > 0;
		}
		this.venuePanic = !this.venue?.isServiceActivated || this.venue?.panicEndAt.isAfter(moment());
		this.loading = false;
		console.log({ open: this.venueOpen, panic: this.venuePanic });
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	onOrder() {
		super.onOrder();
		this.validate();
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo && !this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		await sleep(100);

		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		await sleep(100);
	}

	private async scrollArticleListTo(index: number) {
		await this.articleList.scrollToPoint(0, this.articleList.el.children[index].offsetTop + 1, 300);
	}
	async goHome() {
		// const popover = await this.popoverCtrl.create({
		// 	component: HomePopoverComponent,
		// 	translucent: true,
		// 	mode: 'ios',
		// 	cssClass: 'home-popover'
		// });
		// await popover.present();
		// await popover.onDidDismiss();
		await this.router.navigateByUrl(NAVIGATIONURLS.home());
	}

	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}
	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = !this.searchBarOpen;
		this.reloadCategories();
	}
	togleSwitch(selectedItem: toggleTags) {
		this.selectedToggleTag = selectedItem;
		this.reloadCategories();
	}

	trackId(index, item) {
		return item.id;
	}
	async goToOrderPage() {
		console.log(this.filterFavouriteArticles());
		if (!AppComponent.largeScreen && !this.showDialog && this.filterFavouriteArticles()?.length > 0) {
			const addArticle = await ShowRecomendationArticlesComponent.show(this.modalCtrl, this.order, this.filterFavouriteArticles());
			if (addArticle) {
				await this.openSelectedProduct(addArticle);
				this.loading = false;
				return;
			}
		}
		this.showDialog = true;
		await this.router.navigateByUrl('order');
	}

	filterFavouriteArticles() {
		return ([].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[]).filter(
			it => !!it.tags.find(tag => tag.identifier == 'favorite')
		);
	}

	async loadOrders() {
		if (!this.customer || this.previousOrders.length > 0) {
			return;
		}
		try {
			this.previousOrders = [];

			const response = await Api.getCustomerOrders(this.customer.uid);
			this.previousOrders = response.data.withPayment
				.filter(order => order.referenceOrder === null || order.referenceOrder === undefined || order.referenceOrder === '')
				.reverse();
		} catch (e) {
			this.previousOrders = [];
		}
	}
	async repeatOrder(item: Order) {
		const result = await InfoModalComponent.show(this.modalCtrl, {
			title: this.translate.instant('reorder_info.title'),
			info: this.translate.instant('reorder_info.subtitle'),
			mainButton: this.translate.instant('reorder_info.yes'),
			closeButton: this.translate.instant('reorder_info.no'),
		});
		if (result) {
			item.coupon = [];
			item.orderedArticles = item.orderedArticles.filter(it => !it?.freeArticle || !it?.isPromo);
			await this.repository.reorder(this.translate, item);
		}
	}
	toggleOpenOrdersBlock() {
		this.isOpenOrdersBlock = !this.isOpenOrdersBlock;
	}
	test(value: any) {
		value.preventDefault();
		this.togleSwitch(this.selectedToggleTag === this.toggleTagsEnum.All ? this.toggleTagsEnum.Vegeterian : this.toggleTagsEnum.All);
	}
}
