import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';
import { ScanQrModal } from '../scan-qr/scan-qr.component';

@Component({
	selector: 'app-add-points',
	templateUrl: './app-add-points.component.html',
	styleUrls: ['./app-add-points.component.scss'],
})
export class AppAddPointsComponent {
	points = 18;
	order: Order;
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, order: Order) {
		const resultScan = await modalCtrl.create({
			component: AppAddPointsComponent,
			cssClass: 'scan-qr-modal',
			componentProps: {
				order,
			},
		});

		await resultScan.present();
		const res = (await resultScan.onDidDismiss()).data;
		await sleep(100);
		return res;
	}

	close() {
		this.modalCtrl.dismiss(true);
	}
}
