import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-loyalty-slider',
	templateUrl: './loyalty-slider.component.html',
	styleUrls: ['./loyalty-slider.component.scss'],
})
export class LoyaltySliderComponent implements OnInit {
	public sliderData = [
		{
			title: this.translate.instant('loyalty_slider.first.title'),
			info: this.translate.instant('loyalty_slider.first.info'),
			image: this.translate.instant('loyalty_slider.first.image'),
		},
		{
			title: this.translate.instant('loyalty_slider.second.title'),
			info: [
				this.translate.instant('loyalty_slider.second.info.1'),
				this.translate.instant('loyalty_slider.second.info.2'),
				this.translate.instant('loyalty_slider.second.info.3'),
				this.translate.instant('loyalty_slider.second.info.4'),
			],
		},
		{
			title: this.translate.instant('loyalty_slider.third.title'),
			info: this.translate.instant('loyalty_slider.third.info'),
			image: this.translate.instant('loyalty_slider.third.image'),
		},
		{
			title: this.translate.instant('loyalty_slider.fourth.title'),
			info: this.translate.instant('loyalty_slider.fourth.info'),
			image: this.translate.instant('loyalty_slider.fourth.image'),
		},
		{
			title: this.translate.instant('loyalty_slider.fifth.title'),
			info: this.translate.instant('loyalty_slider.fifth.info'),
			image: this.translate.instant('loyalty_slider.fifth.image'),
		},
	];
	public isAnonymous: boolean = true;
	public slideOpts = {
		initialSlide: 0,
		speed: 400,
		autoplay: true,
	};
	constructor(
		private translate: TranslateService,
		private modalCtrl: ModalController
	) {}

	ngOnInit() {}
	static async show(modalCtrl: ModalController, isAnonymous: boolean): Promise<boolean> {
		const modal = await modalCtrl.create({
			component: LoyaltySliderComponent,
			cssClass: 'delivery-modal full-height',
			componentProps: {
				isAnonymous,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	cancel() {
		this.modalCtrl.dismiss();
	}
	register() {
		this.modalCtrl.dismiss(true);
	}
}
