import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-speisekarte-modal',
	templateUrl: './speisekarte-modal.component.html',
	styleUrls: ['./speisekarte-modal.component.scss'],
})
export class SpeisekarteModalComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	ngOnInit(): void {}

	items: { link: string; title: string }[];

	static async show(modalCtrl: ModalController, items: { link: string; title: string }[]) {
		const modal = await modalCtrl.create({
			component: SpeisekarteModalComponent,
			cssClass: 'speisekarte-modal auto-height',
			componentProps: {
				items,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		await modal.onDidDismiss();
		await sleep(200);
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
