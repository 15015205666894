import {ApplicationRef, EventEmitter, Injectable} from '@angular/core';
import Venue from '../../models/Venue';
import Order from '../../models/Order';
import Payment from '../../models/Payment';
import Customer from '../../models/Customer';
import Address from '../../models/Address';
import {Api} from '../../api/api';
import {PreorderType} from '../../enums/PreorderType';
import {OrderType} from '../../enums/OrderType';
import Preorder from '../../models/Preorder';
import OptionGroup from '../../models/OptionGroup';
import Article from '../../models/Article';
import {OrderUtils} from '../../utils/order-utils';
import {TimeUtils} from '../../utils/time-utils';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {AuthLoginResponse} from '../../models/AuthLoginResponse';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';
import {CustomerAuth} from '../../models/CustomerAuth';
import {
	PaymentModalComponent,
	PaymentModalResult
} from '../../components/payment-modal/payment-modal.component';
import {DisplayIdentifier} from '../../enums/DisplayIdentifier';
import {
	axiosErrorToMessage,
	getSlots,
	isActive,
	numberComparator,
	paymentFromOrders,
	venueAcceptsOrders
} from '../../utils/utils';
import {AnalyticsService} from '../analytics/analytics.service';
import {DisplayMode} from '../../enums/DisplayMode';
import Table from 'src/smoothr-web-app-core/models/Table';
import {debounceTime, delay, delayWhen, map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';

import {OrderStatus} from 'src/smoothr-web-app-core/enums/OrderStatus';
import {NavigationService} from 'src/app/services/navigation.service';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import {PaymentMethod} from 'src/smoothr-web-app-core/enums/PaymentMethod';

const localStorageOrderKey = 'repository-service-order';
const localStorageVerifiedOrderKey = 'repository-service-verified-order';
const localStorageCustomerKey = 'repository-service-customer-auth';
const localStoragePaymentKey = 'repository-service-payment';
const localStorageAddressKey = 'repository-service-address';
const localStoragePreviousPreorderKey = 'repository-service-previous-preorder';
const localStorageAuthLoginKey = 'repository-service-auth-login';
const localStorageFavouriteKey = 'repository-service-favourite';

@Injectable({
	providedIn: 'root'
})
export class RepositoryService {
	venue = new BehaviorSubject<Venue>(null);
	address = new BehaviorSubject<Address>(null);
	order = new BehaviorSubject<Order>(null);
	verifiedOrder = new EventEmitter<Order>();
	// payment = new BehaviorSubject<Payment>(null);
	payment = new EventEmitter<Payment>();

	customerAuth = new BehaviorSubject<CustomerAuth>(null);
	previousPreorder = new BehaviorSubject<Preorder>(null);
	authLogin = new BehaviorSubject<AuthLoginResponse>(null);
	public isLogin$: Observable<boolean> = this.customerAuth.pipe(
		debounceTime(150),
		switchMap(customerData => of(!customerData.customer.isAnonymous))
	);
	favorite = new BehaviorSubject<string[]>([]);

	_venue: Venue;
	_address: Address;
	_order: Order;
	_verifiedOrder: Order;
	_payment: Payment;
	_customerAuth: CustomerAuth;
	_previousPreorder: Preorder;
	_authLogin: AuthLoginResponse;
	_favorite: string[];

	private apiTokenRefreshSubscription: Subscription;

	private venuePromise: Promise<Venue> = null;
	private venuePromiseRunning: string = null;

	constructor(
		private appRef: ApplicationRef,
		private analytics: AnalyticsService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private navService: NavigationService
	) {
		this._venue = null;
		try {
			this._order = JSON.parse(localStorage.getItem(localStorageOrderKey));
			this.order.next(this._order);
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageOrderKey, null);
		}
		try {
			this._payment = JSON.parse(localStorage.getItem(localStoragePaymentKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStoragePaymentKey, null);
		}
		try {
			this._customerAuth = JSON.parse(
				localStorage.getItem(localStorageCustomerKey)
			);
			this.customerAuth.next(this._customerAuth);
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageCustomerKey, null);
		}
		try {
			this._address = JSON.parse(localStorage.getItem(localStorageAddressKey));
			this.address.next(this._address);
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageAddressKey, null);
		}
		try {
			this._verifiedOrder = JSON.parse(
				localStorage.getItem(localStorageVerifiedOrderKey)
			);
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageVerifiedOrderKey, null);
		}
		try {
			this._previousPreorder = JSON.parse(
				localStorage.getItem(localStoragePreviousPreorderKey)
			);
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStoragePreviousPreorderKey, null);
		}
		try {
			this._authLogin = JSON.parse(
				localStorage.getItem(localStorageAuthLoginKey)
			);
			this.authLogin.next(this._authLogin);
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageAuthLoginKey, null);
		}
		try {
			this._favorite = JSON.parse(
				localStorage.getItem(localStorageFavouriteKey)
			);
			this.favorite.next(this._favorite);
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageFavouriteKey, JSON.stringify([]));
		}
		this.order.subscribe(order => {
			if (order) {
				OrderUtils.injectRequiredArticles(this._venue, order, analytics);
				OrderUtils.injectDeliveryFees(this._venue, order);
				order.orderedArticles = order.orderedArticles.map(articleGroup => {
					articleGroup.groups = articleGroup.groups.sort((a, b) =>
						numberComparator(a.article.sortOrder, b.article.sortOrder)
					);
					return articleGroup;
				});
				if (
					this.customerAuth &&
					order &&
					!this._customerAuth?.customer?.isAnonymous &&
					this._customerAuth?.customer?.piggyPoints > 0
				) {
					const sum = (
						JSON.parse(JSON.stringify(order.orderedArticles)) as ArticleGroup[]
					)
						.filter(it => it?.redeemPointsWithPiggy > 0)
						.reduce((a, b) => {
							const sumOfPoints = b?.redeemPointsWithPiggy * b?.quantity;
							return a + sumOfPoints;
						}, 0);
					this._customerAuth.customer.usedPiggyPoints = sum;
					this.customerAuth.next(this._customerAuth);
				}
				if (this._customerAuth.customer.uid) {
					order.userUid = this._customerAuth?.customer?.uid;
				}
			}
			this._order = order;
			localStorage.setItem(localStorageOrderKey, JSON.stringify(order));
			appRef.tick();
		});
		this.verifiedOrder.subscribe(verifiedOrder => {
			this._verifiedOrder = verifiedOrder;
			localStorage.setItem(
				localStorageVerifiedOrderKey,
				JSON.stringify(verifiedOrder)
			);
			appRef.tick();
		});
		this.address.subscribe(address => {
			this._address = address;
			localStorage.setItem(localStorageAddressKey, JSON.stringify(address));
			appRef.tick();
		});
		this.payment.subscribe(payment => {
			this._payment = payment;
			localStorage.setItem(localStoragePaymentKey, JSON.stringify(payment));
			appRef.tick();
		});
		this.customerAuth.subscribe(async customerAuth => {
			if (!customerAuth) {
				console.log('customer logged out');
				// customer was online and now was logged out.
				if (
					this._customerAuth &&
					this._customerAuth.customer &&
					!this._customerAuth.customer.isAnonymous
				) {
					this.snackBarCtrl.open(
						this.translate.instant('repository_service.login_timed_out'),
						null,
						{
							duration: 3000
						}
					);
				}
				try {
					const response = (
						await Api.signInAnonymous(customerAuth?.customer?.uid)
					).data;
					this.customerAuth.next(response);
				} catch (e) {
					console.error(e);
					this.snackBarCtrl.open(axiosErrorToMessage(this.translate, e), null, {
						duration: 2000
					});
					localStorage.setItem(localStorageCustomerKey, JSON.stringify(null));
				}
				if (this._order && this._order?.orderedArticles?.length > 0) {
					if (
						this._order.orderedArticles.find(it => it.redeemWithPiggyPoints)
					) {
						this._order.orderedArticles = this._order.orderedArticles.filter(
							it => !it.redeemWithPiggyPoints
						);
						this.order.next(this._order);
					}
				}

				return;
			}

			if (!customerAuth.customer?.country && customerAuth?.customer) {
				customerAuth.customer.country = environment.countryList[0];
			} else {
				if (customerAuth?.customer) {
					customerAuth.customer.country =
						customerAuth.customer.country.toLowerCase() ?? '';
				}
			}
			this._customerAuth = customerAuth;
			Api.authToken = this._customerAuth.token;
			Api.refreshToken = this._customerAuth.refreshToken;
			localStorage.setItem(
				localStorageCustomerKey,
				JSON.stringify(customerAuth)
			);

			appRef.tick();
		});
		this.apiTokenRefreshSubscription = Api.onTokenRefreshed.subscribe(
			customerAuth => {
				this.customerAuth.next(customerAuth);
			}
		);
		this.venue.subscribe(venue => {
			this._venue = venue;
			this.order.next(this._order);
			appRef.tick();
		});
		this.previousPreorder.subscribe(preorder => {
			this._previousPreorder = preorder;
			localStorage.setItem(
				localStoragePreviousPreorderKey,
				JSON.stringify(preorder)
			);
		});
		this.authLogin.subscribe(authLogin => {
			this._authLogin = authLogin;
			localStorage.setItem(localStorageAuthLoginKey, JSON.stringify(authLogin));
		});
		this.favorite.subscribe(favourite => {
			this._favorite = favourite;
			localStorage.setItem(localStorageFavouriteKey, JSON.stringify(favourite));
		});

		this.address.next(this._address);
		this.order.next(this._order);
		this.verifiedOrder.emit(this._verifiedOrder);
		this.payment.emit(this._payment);
		this.customerAuth.next(this._customerAuth);
		this.authLogin.next(this._authLogin);
	}

	get customer(): Customer {
		return this._customerAuth && this._customerAuth.customer;
	}

	async reloadCustomer(): Promise<Customer> {
		if (!this._customerAuth) {
			return null;
		}
		try {
			const response = await Api.getCustomer();
			this._customerAuth.customer = response?.data;
		} catch (e) {
			console.error(e);
		}
		this.customerAuth.next(this._customerAuth);
		return this._customerAuth.customer;
	}

	resetVenue() {
		this._venue = null;
		this.venue.next(this._venue);
	}

	getVenue(forcedId: string | null = null): Promise<Venue> {
		let venueId = forcedId;
		if (venueId === null) {
			venueId = this.getStoredVenueId();
		}
		if (venueId === null || venueId.length <= 0) {
			if (this._venue) {
				return new Promise<Venue>(resolve => resolve(this._venue));
			} else {
				return new Promise<Venue>((resolve, reject) =>
					reject('No Venue ID Provided')
				);
			}
		}
		if (this._venue && venueId === this._venue._id) {
			this.venue.next(this._venue);
			return new Promise<Venue>(resolve => resolve(this._venue));
		}
		if (this.venuePromiseRunning === venueId) {
			return this.venuePromise;
		}
		this.venuePromiseRunning = venueId;
		this.venuePromise = new Promise<Venue>(async (resolve, reject) => {
			try {
				const venue = (await Api.getLazyVenue(venueId)).data;

				venue.openingHours = TimeUtils.sanitizeHours(venue.openingHours);
				venue.deliveryHours = TimeUtils.sanitizeHours(venue.deliveryHours);
				venue.articleCategories = (
					await Api.getCategoriesWithArticles(venue._id, true)
				).data;
				venue.panicEndAt = venue.panicEndAt && moment(venue.panicEndAt);
				venue.panicEndAt = venue.panicEndAt || moment().add(-1, 'day');
				const optionGroups: OptionGroup[] = (
					await Api.getOptionGroupsByVenue(venue._id)
				).data.map(og => {
					og.displayMode = og.displayModeMobile ?? og.displayMode;
					if (og.displayMode === DisplayMode.DEFAULT) {
						og.displayMode = DisplayMode.RADIO;
					}
					return og;
				});
				const allArticles = [];
				venue.articleCategories.forEach(cat => {
					allArticles.push(...cat.articles);
				});
				venue.articleCategories = venue.articleCategories
					.map(category => {
						category.availableHours = TimeUtils.sanitizeHours(
							category.availableHours
						);
						return category;
					})
					.map(category => {
						// filter inactive categories
						category.articles = category.articles
							.map(article => {
								// filter inactive groups (empty options)
								article.groups = article.groups
									.map(groupId => {
										// @ts-ignore
										const group = optionGroups.find(grp => grp._id === groupId);
										// filter inactive options
										group.articles = group.articles
											.map(optionArticle =>
												this.sanitizeArticle(venue, optionArticle)
											)
											.sort((a, b) =>
												numberComparator(a.sortOrder, b.sortOrder)
											);
										return group;
									})
									.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder))
									.filter(group => group.articles.length > 0);
								article = this.sanitizeArticle(venue, article);
								return article;
							})
							.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder));
						return category;
					})
					.map(category => {
						category.hidden = !(
							category.visible &&
							category.articles.length > 0 &&
							(!category.availableHours ||
								category.availableHours.length === 0 ||
								TimeUtils.doesHoursMatchNow(category.availableHours))
						);
						return category;
					})
					.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder));
				this._venue = venue;
				this.venue.next(this._venue);
				resolve(this._venue);
			} catch (e) {
				this.venue.next(null);
				this.venue.error(e);
				reject(e);
			}
		}).finally(() => {
			this.venuePromiseRunning = null;
		});
		return this.venuePromise;
	}

	getVenueAndLoadAllCategories(venueIdValue: string): Promise<Venue> {
		let venueId = venueIdValue;

		if (venueId === null || venueId.length <= 0) {
			if (this._venue) {
				return new Promise<Venue>(resolve => resolve(this._venue));
			} else {
				return new Promise<Venue>((resolve, reject) =>
					reject('No Venue ID Provided')
				);
			}
		}

		if (this.venuePromiseRunning === venueId) {
			return this.venuePromise;
		}
		this.venuePromiseRunning = venueId;
		this.venuePromise = new Promise<Venue>(async (resolve, reject) => {
			try {
				const venue = (await Api.getLazyVenue(venueId)).data;

				venue.openingHours = TimeUtils.sanitizeHours(venue.openingHours);
				venue.deliveryHours = TimeUtils.sanitizeHours(venue.deliveryHours);
				venue.articleCategories = (
					await Api.getCategoriesWithArticles(venue._id, true)
				).data;
				venue.panicEndAt = venue.panicEndAt && moment(venue.panicEndAt);
				venue.panicEndAt = venue.panicEndAt || moment().add(-1, 'day');
				const optionGroups: OptionGroup[] = (
					await Api.getOptionGroupsByVenue(venue._id)
				).data.map(og => {
					og.displayMode = og.displayModeMobile ?? og.displayMode;
					if (og.displayMode === DisplayMode.DEFAULT) {
						og.displayMode = DisplayMode.RADIO;
					}
					return og;
				});
				const allArticles = [];
				venue.articleCategories.forEach(cat => {
					allArticles.push(...cat.articles);
				});
				venue.articleCategories = venue.articleCategories
					.map(category => {
						category.availableHours = TimeUtils.sanitizeHours(
							category.availableHours
						);
						return category;
					})
					.map(category => {
						// filter inactive categories
						category.articles = category.articles
							.map(article => {
								// filter inactive groups (empty options)
								article.groups = article.groups
									.map(groupId => {
										// @ts-ignore
										const group = optionGroups.find(grp => grp._id === groupId);
										// filter inactive options
										group.articles = group.articles
											.map(optionArticle =>
												this.sanitizeArticle(venue, optionArticle)
											)
											.sort((a, b) =>
												numberComparator(a.sortOrder, b.sortOrder)
											);
										return group;
									})
									.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder))
									.filter(group => group.articles.length > 0);
								article = this.sanitizeArticle(venue, article);
								return article;
							})
							.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder));
						return category;
					})
					.map(category => {
						category.hidden = !(
							category.visible &&
							category.articles.length > 0 &&
							(!category.availableHours ||
								category.availableHours.length === 0 ||
								TimeUtils.doesHoursMatchNow(category.availableHours))
						);
						return category;
					})
					.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder));
				const finalVenue = venue;

				resolve(finalVenue);
			} catch (e) {
				reject(e);
			}
		}).finally(() => {
			this.venuePromiseRunning = null;
		});
		return this.venuePromise;
	}

	async getTable(venueId: string, tableNumber: string): Promise<Table> {
		return (await Api.getTable(venueId, tableNumber)).data;
	}
	updateOrder(
		venue: Venue,
		order: Order,
		address: Address,
		orderType: OrderType = OrderType.PREORDER,
		preorderType: PreorderType,
		table?: Table
	) {
		order.orderedArticles = [];
		order._id = undefined;
		order.venue = venue._id;
		order.currency = venue.currency;
		order.type = orderType;
		order.coupon = order.coupon;
		if (order.type === OrderType.PREORDER) {
			order.preorder = new Preorder();
			order.preorder.number = '';
			order.preorder.type = preorderType;
			order.preorder.personCount = 1;
			order.table = venue.preorderTable;
			order.tableNumber = 'preorder_table';
			if (
				preorderType === PreorderType.TAKE_AWAY ||
				preorderType === PreorderType.INSIDE ||
				preorderType === PreorderType.PARK_COLLECT
			) {
				order.preorder.street = venue.street;
				order.preorder.city = venue.city.de;
				order.preorder.number = venue.number;
				order.preorder.postalCode = venue.postalCode;
				order.preorder.country = venue.country;
				order.preorder.lat = venue.location.coordinates[1];
				order.preorder.lng = venue.location.coordinates[0];
			} else if (address) {
				order.preorder.street = address.street;
				order.preorder.city = address.city;
				order.preorder.number = address.number;
				order.preorder.postalCode = address.postalCode;
				order.preorder.country = address.country;
				order.preorder.lat = address.lat;
				order.preorder.lng = address.lng;
			}
		} else if (order.type === OrderType.CATERING) {
			order.preorder = new Preorder();
			order.preorder.street = venue.street;
			order.preorder.city = venue.city.de;
			order.preorder.number = venue.number;
			order.preorder.postalCode = venue.postalCode;
			order.preorder.country = venue.country;
			order.preorder.lat = venue.location.coordinates[1];
			order.preorder.lng = venue.location.coordinates[0];
		} else if (order.type === OrderType.STANDARD) {
			order.table = table?._id;
			order.tableNumber = table?.number;
			order.preorder = new Preorder();
		}
		this.address.next(address);
		this.order.next(order);
	}
	createOrder(
		venue: Venue,
		address: Address,
		orderType: OrderType = OrderType.PREORDER,
		preorderType: PreorderType,
		table?: Table
	) {
		const order = new Order();
		order.orderedArticles = [];
		order._id = undefined;
		order.venue = venue._id;
		order.currency = venue.currency;
		order.type = orderType;
		order.coupon = [];
		if (order.type === OrderType.PREORDER) {
			order.preorder = new Preorder();
			order.preorder.number = '';
			order.preorder.type = preorderType;
			order.preorder.personCount = 1;
			order.table = venue.preorderTable;
			order.tableNumber = 'preorder_table';
			if (
				preorderType === PreorderType.TAKE_AWAY ||
				preorderType === PreorderType.INSIDE
			) {
				order.preorder.street = venue.street;
				order.preorder.city = venue.city.de;
				order.preorder.number = venue.number;
				order.preorder.postalCode = venue.postalCode;
				order.preorder.country = venue.country;
				order.preorder.lat = venue.location.coordinates[1];
				order.preorder.lng = venue.location.coordinates[0];
			} else if (address) {
				order.preorder.street = address.street;
				order.preorder.city = address.city;
				order.preorder.number = address.number;
				order.preorder.postalCode = address.postalCode;
				order.preorder.country = address.country;
				order.preorder.lat = address.lat;
				order.preorder.lng = address.lng;
			}
		} else if (order.type === OrderType.CATERING) {
			order.preorder = new Preorder();
			order.preorder.street = venue.street;
			order.preorder.city = venue.city.de;
			order.preorder.number = venue.number;
			order.preorder.postalCode = venue.postalCode;
			order.preorder.country = venue.country;
			order.preorder.lat = venue.location.coordinates[1];
			order.preorder.lng = venue.location.coordinates[0];
		} else if (order.type === OrderType.STANDARD) {
			order.table = table?._id;
			order.tableNumber = table?.number;
			order.preorder = new Preorder();
		}
		this.address.next(address);
		this.order.next(order);
	}

	async sendOrderAndPay(
		modalCtrl: ModalController,
		order: Order,
		tip: number,
		authToken: string,
		customer: Customer,
		keepPreorder = false,
		forceAddressInput = false
	): Promise<void> {
		order.userUid = customer.uid;
		this.order.next(order);
		return new Promise<void>(async (resolve, reject) => {
			try {
				const orderCopy: Order = JSON.parse(JSON.stringify(this._order));
				if (keepPreorder) {
					orderCopy.type = OrderType.STANDARD;
				}
				if (authToken) {
					const testOrderRes = await Api.createTestOrder(authToken, orderCopy);
					const testPayment = new Payment();
					testPayment._id = testOrderRes.data.payment;
					this.payment.emit(testPayment);
					this.verifiedOrder.emit(testOrderRes.data.order);

					resolve();
					return;
				}
				const response = await Api.createOrder(orderCopy, keepPreorder);
				this.analytics.orderVerified(order);
				const venue = await this.getVenue();
				const verifiedOrder = response.data;
				if (Number(response.data.totalPrice) === 0) {
					const payment = paymentFromOrders([response.data], tip);
					payment.method = PaymentMethod.ZERO_PAYMENT;
					const createdPayment = await Api.createPayment(payment);
					this.verifiedOrder.emit(verifiedOrder);
					this.payment.emit(createdPayment);
					resolve();
					return;
				}
				this.verifiedOrder.next(verifiedOrder);
				const result = await PaymentModalComponent.show(
					modalCtrl,
					venue,
					[verifiedOrder],
					customer,
					tip,
					forceAddressInput
				);
				if (result && result.result === PaymentModalResult.SUCCESS) {
					this.verifiedOrder.emit(verifiedOrder);
					this.payment.emit(result.payment);
					resolve();
					return;
				}
				reject(result && result.error ? result.error : null);
			} catch (e) {
				reject(e);
			}
		});
	}

	getStoredVenueId(): string {
		return this._order ? this._order.venue : null;
	}

	async reorder(translate: TranslateService, order: Order) {
		const anyOrder = JSON.parse(JSON.stringify(order));
		anyOrder.totalPrice = undefined;
		anyOrder.orderedArticles = anyOrder.orderedArticles.map(oa => {
			oa.totalPrice = undefined;
			return oa;
		});
		const localOrder: Order = anyOrder;
		localOrder.status = undefined;
		const venue = await this.getVenue(localOrder.venue);
		if (
			OrderUtils.isDelivery(localOrder) &&
			venue.deliveryEnabled &&
			venue.deliveryPostalCodes &&
			venue.deliveryPostalCodes.length > 0
		) {
			venue.isPostalDelivery = true;
		}
		if (!venueAcceptsOrders(venue, localOrder.preorder.type)) {
			throw {
				message: translate.instant('errors.reorder_venue_not_open')
			};
		}
		const articleList = [];
		for (const category of venue.articleCategories) {
			for (const article of category.articles) {
				if (article.visible) {
					articleList.push(article);
					for (const group of article.groups) {
						for (const optionArticle of group.articles) {
							articleList.push(optionArticle);
						}
					}
				}
			}
		}
		localOrder.orderedArticles = localOrder.orderedArticles
			.map(oa => {
				oa.article = articleList.find(
					article =>
						oa.article._id === article._id &&
						isActive(
							articleList,
							article,
							localOrder.type,
							localOrder.preorder.type
						)
				);
				if (!oa.article) {
					return null;
				}
				oa.groups = oa.groups
					.map(ao => {
						ao.article = articleList.find(
							article =>
								ao.article._id === article._id &&
								isActive(
									articleList,
									article,
									localOrder.type,
									localOrder.preorder.type
								)
						);
						if (!ao.article) {
							return null;
						}
						return ao;
					})
					.filter(ao => !!ao);
				return oa;
			})
			.filter(oa => !!oa);
		localOrder.orderAt = null;
		localOrder._id = null;
		localOrder.coupon = localOrder?.coupon || [];
		this.order.next(OrderUtils.removePromo(localOrder));
		await this.addPointsToArticle();
	}

	async getAllVenues(): Promise<Venue[]> {
		return (await Api.getAllVenues()).data.map(ven => {
			try {
				ven.panicEndAt = ven.panicEndAt && moment(ven.panicEndAt);
				// if no panic set to "yesterday" to keep non-null and as "Moment"
				ven.panicEndAt = ven.panicEndAt || moment().add(-1, 'day');
				ven.openingHours = TimeUtils.sanitizeHours(ven.openingHours);
				ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
			} catch (e) {
				console.error({
					message: 'Error while sanitizing hours ' + e,
					venue: ven.name + ' ' + ven.readableId,
					openingHours: ven.openingHours,
					deliveryHours: ven.deliveryHours
				});
			}
			return ven;
		});
	}

	async getVenuesByAddress(address: Address): Promise<Venue[]> {
		const venues: Venue[] = [];
		if (address.postalCode) {
			venues.push(
				...(await this.getVenues(null, null, address.postalCode))
					.map(ven => {
						ven.isPostalDelivery = true;
						return ven;
					})
					.filter(ven => {
						return venueAcceptsOrders(ven, PreorderType.DELIVERY);
					})
			);
		}
		const ids = venues.map(ven => ven._id);
		(await this.getVenues(address.lat, address.lng, null))
			.filter(ven => {
				return (
					venueAcceptsOrders(ven, PreorderType.DELIVERY) ||
					venueAcceptsOrders(ven, PreorderType.TAKE_AWAY)
				);
			})
			.forEach(ven => {
				if (ids.indexOf(ven._id) < 0) {
					venues.push(ven);
				}
			});
		return venues;
	}

	async getVenues(
		lat: number | null,
		lng: number | null,
		postalCode: string | null
	): Promise<Venue[]> {
		const response = await (postalCode
			? Api.getVenuesBy(postalCode)
			: Api.getVenues(lat, lng, 50, false));
		return response.data
			.map(venue => {
				venue.openingHours = TimeUtils.sanitizeHours(venue.openingHours);
				venue.deliveryHours = TimeUtils.sanitizeHours(venue.deliveryHours);
				venue.isPostalDelivery = !!postalCode;
				if (venue.deliveryByRadius === undefined) {
					venue.deliveryByRadius = false;
				}
				return venue;
			})
			.filter(async venue => {
				try {
					if (
						venue.panicEndAt &&
						moment(venue.panicEndAt).isSameOrAfter(moment())
					) {
						return false;
					}
					return (
						(await getSlots(venue, OrderType.PREORDER, PreorderType.DELIVERY))
							.length !== 0 ||
						(await getSlots(venue, OrderType.PREORDER, PreorderType.TAKE_AWAY))
							.length !== 0
					);
				} finally {
				}
				return false;
			});
	}

	makeId(length: number): string {
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	async sendOrderStandard(
		modalCtrl: ModalController,
		order: Order,
		customer: Customer,
		keepPreorder: boolean = false
	): Promise<Order> {
		order.userUid = customer.uid;
		order.orderAt = moment().add(1, 'minute');
		return new Promise<Order>(async (resolve, reject) => {
			try {
				const orderCopy: Order = JSON.parse(JSON.stringify(order));
				const response = await Api.createOrder(orderCopy, keepPreorder);
				const verifiedOrder = response.data;
				this.analytics.orderVerified(verifiedOrder);
				resolve(verifiedOrder);
				const table = (
					await Api.getTable(orderCopy.venue, orderCopy.tableNumber)
				).data;
				this.createOrder(
					this._venue,
					this._address,
					OrderType.STANDARD,
					PreorderType.INSIDE,
					table
				);
				delay(100);
				this._order.table = table._id;
				this._order.tableNumber = table.number;
				this.order.next(this._order);
			} catch (e) {
				reject(e);
			}
		});
	}

	private sanitizeArticle(venue: Venue, article: Article): Article {
		if (article && article.groups) {
			article.isFromPrice =
				article.groups
					.map(
						grp =>
							grp.displayIdentifiers &&
							grp.displayIdentifiers.indexOf(DisplayIdentifier.fullPrice) >= 0
					)
					.indexOf(true) >= 0;
		}
		article.availableHours = TimeUtils.sanitizeHours(article.availableHours);
		article.hidden = !(
			article.visible &&
			article.isActive &&
			(!article.availableHours ||
				article.availableHours.length === 0 ||
				TimeUtils.doesHoursMatchNow(article.availableHours))
		);
		if (article.displayPrice) {
			article.price = article.displayPrice
				? article.displayPrice
				: article.price;
		}
		if (article.displayPriceByType) {
			const byType = article.displayPriceByType;
			if (byType.standard) {
				article.priceByType.standard = byType.standard;
			}
			if (byType.preorder.delivery) {
				article.priceByType.preorder.delivery = byType.preorder.delivery;
			}
			if (byType.preorder.inside) {
				article.priceByType.preorder.inside = byType.preorder.inside;
			}
			if (byType.preorder.takeAway) {
				article.priceByType.preorder.takeAway = byType.preorder.takeAway;
			}
			if (byType.terminal.inside) {
				article.priceByType.terminal.inside = byType.terminal.inside;
			}
			if (byType.terminal.takeAway) {
				article.priceByType.terminal.takeAway = byType.terminal.takeAway;
			}
		}
		if (!article.tags) {
			article.tags = [];
		}
		article.tags = article.tags
			.filter(tag => tag !== undefined && tag !== null)
			.map(tag => {
				if (tag._id === undefined) {
					// @ts-ignore
					return venue.tags.find(srcTag => tag === srcTag._id);
				}
				return tag;
			});
		return article;
	}
	async cleanRepository() {
		this.resetVenue();
		this.address.next(null);
		this.order.next(null);
		this.verifiedOrder.next(null);
		this.payment.emit(null);
	}

	async createOrderWithPayment(
		modalCtrl: ModalController,
		translate: TranslateService,
		order: Order,
		tip: number,
		loading: (loading: boolean) => void,
		snackbarCtrl: MatSnackBar,
		keepPreorder = false,
		forceAddressInput = false
	) {
		this._order._id = undefined;
		loading(true);
		try {
			if (!this._order.table) {
				this._order.table = this._venue.preorderTable;
			}
			this._order.status = OrderStatus.CREATED;
			await this.sendOrderAndPay(
				modalCtrl,
				order,
				tip,
				this._authLogin ? this._authLogin.token : null,
				this._customerAuth.customer,
				keepPreorder,
				forceAddressInput
			);
			await this.navService.paymentSuccess(
				this._verifiedOrder._id,
				this._payment._id
			);
			// await navPaymentSuccess(router, repository._verifiedOrder._id, repository._payment._id);
		} catch (err) {
			if (err !== null) {
				const message = axiosErrorToMessage(translate, err, order.orderAt);
				console.error(message);
				snackbarCtrl.open(message, null, {
					duration: 5000
				});
			}
			this._order.orderAt = null;
			this.order.next(order);
		}
		loading(false);
	}
	addFavourite(articleNumber: string) {
		if (articleNumber) {
			let favouriteArticles = this._favorite;
			if (favouriteArticles && favouriteArticles.length > 0) {
				const foundArticle = favouriteArticles.includes(articleNumber);
				if (!foundArticle) {
					favouriteArticles.push(articleNumber);
					this.favorite.next(favouriteArticles);
				}
			} else {
				favouriteArticles = [];
				favouriteArticles.push(articleNumber);
				this.favorite.next(favouriteArticles);
			}
		}
	}
	checkIfArticleIsFavourite(articleNumber: string) {
		return this._favorite && this._favorite.length > 0
			? this._favorite.includes(articleNumber)
			: false;
	}
	deleteFavourite(articleNumber: string) {
		if (
			articleNumber &&
			this._favorite &&
			this._favorite.length > 0 &&
			this._favorite.includes(articleNumber)
		) {
			const deletedArticle = this._favorite.filter(it => it !== articleNumber);
			this.favorite.next(deletedArticle);
		}
	}
	async addPointsToArticle() {
		if (
			this.customer &&
			!this.customer.isAnonymous &&
			this._order &&
			this._order.orderedArticles.length > 0
		) {
			try {
				const balance = await Api.getAmountOfPoints(
					OrderUtils.orderTotalPrice(this._order, true, true, true).toString(),
					this.customer.email,
					this._order.venue
				);
				this._order.piggyPoints = balance.data?.data?.credits;
				this.order.next(this._order);
			} catch (e) {
				this._order.piggyPoints = 0;
				this.order.next(this._order);
			}
		} else {
			if (this._order) {
				this._order.piggyPoints = 0;
				if (this.customer && this.customer.isAnonymous) {
					this._order.userUid = this.customer.uid;
				}
				this.order.next(this._order);
			}
		}
	}
}
