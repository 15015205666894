import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const theAshCustomerGroup = 'the_ash';
export const customerGroup = theAshCustomerGroup;

const supportEmail = {
	the_ash: 'the_ash@smoothr.de',
};
const firebaseConfig = {
	the_ash: {
		apiKey: 'AIzaSyDDScBLwIApVVe2sRymWdUXN1L1jQ_8bLE',
		authDomain: 'the-ash-web-app.firebaseapp.com',
		projectId: 'the-ash-web-app',
		storageBucket: 'the-ash-web-app.appspot.com',
		messagingSenderId: '886144071941',
		appId: '1:886144071941:web:bc134c46faa942888d5f6c',
		measurementId: 'G-ZLBLC14JG0',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyC14Rv5p8LpzU4IiEmHPSd2EcC0u-eUdLw',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	gaId: '',
	facebookPixel: '',
	urlQrCode: 'smoothr://',
};
