import { environment } from './../../../environments/environment';
import { PreorderType } from './../../../smoothr-web-app-core/enums/PreorderType';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';
import { AuthLoginResponse } from 'src/smoothr-web-app-core/models/AuthLoginResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-image-toolbar',
	templateUrl: './image-toolbar.component.html',
	styleUrls: ['./image-toolbar.component.scss'],
})
export class ImageToolbarComponent implements OnInit {
	@Output()
	goToMenu = new EventEmitter<void>();
	@Output()
	close = new EventEmitter<void>();
	@Input()
	venue: Venue;
	@Input()
	vegeterian: boolean;
	@Input()
	order: Order;
	@Input()
	preorderType: PreorderType;
	@Input()
	authLogin: AuthLoginResponse = null;
	environment = environment;
	pr = PreorderType;
	constructor(public translate: TranslateService) {}

	ngOnInit() {}

	navigate() {
		this.goToMenu.emit();
	}
	goHome() {
		this.goToMenu.emit();
	}
}
