import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Api } from 'src/smoothr-web-app-core/api/api';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import { LoyaltyHistory } from 'src/smoothr-web-app-core/models/LoyaltyHistory';
import { LoyaltyOrder } from 'src/smoothr-web-app-core/models/LoyaltyOrder';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-show-history-points',
	templateUrl: './show-history-points.component.html',
	styleUrls: ['./show-history-points.component.scss'],
})
export class ShowHistoryPointsComponent implements OnInit {
	order: Order;
	customer: Customer;
	months: string[] = [];
	ordersByMonth: { [month: string]: LoyaltyOrder[] } = {};
	loading = false;
	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}
	static async show(modalCtrl: ModalController, order: Order, customer: Customer) {
		const modal = await modalCtrl.create({
			component: ShowHistoryPointsComponent,
			cssClass: ' item-modal',
			componentProps: {
				order,
				customer,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		await modal.onDidDismiss();
	}
	async ngOnInit() {
		moment.locale(this.translate.getDefaultLang());
		await this.loadAllHistoryPoints();
	}

	async loadAllHistoryPoints() {
		this.loading = true;

		try {
			const result = await Api.getLoyaltyHistory(this.customer.uid);
			this.groupByMonth(result.data[0]);
			this.loading = false;
		} catch (e) {
			this.loading = false;
		}
	}

	groupByMonth(statistic: LoyaltyHistory) {
		// Group orders by month
		if (statistic && statistic?.orders && statistic?.orders?.length > 0) {
			statistic.orders.forEach(order => {
				const month = moment(order.finishedAt).format('MMMM yyyy');

				if (!this.ordersByMonth[month]) {
					this.ordersByMonth[month] = [];
					this.months.push(month);
				}

				this.ordersByMonth[month].push(order);
			});

			// Sort months in descending order
			this.months.sort((a, b) => moment(b, 'MMMM yyyy').diff(moment(a, 'MMMM yyyy')));

			//Sort order in descending order
			this.months.forEach(month => {
				this.ordersByMonth[month].sort((a, b) => moment(b.finishedAt).diff(moment(a.finishedAt)));
			});
		}
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
