import { Directive, ElementRef, Input } from '@angular/core';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Directive({
	selector: '[swiperElement]',
})
export class SwiperDirective {
	private readonly swiperElement: HTMLElement;
	@Input() config?: SwiperOptions;

	constructor(private element: ElementRef<HTMLElement>) {
		this.swiperElement = element.nativeElement;
	}

	ngAfterViewInit(): void {
		Object.assign(this.element.nativeElement, this.config);
	}
}
