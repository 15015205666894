import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Coupon } from 'src/smoothr-web-app-core/models/Coupon';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-coupon-item',
	templateUrl: './coupon-item.component.html',
	styleUrls: ['./coupon-item.component.scss'],
})
export class CouponItemComponent {
	constructor() {}
	@Input() ashPointItems: Coupon[] = [];
	@Output() buttonClick = new EventEmitter<Coupon>();
	@Input() customerData: Customer;
	@Input() order: Order;

	selectItem(item: Coupon) {
		this.buttonClick.emit(item);
	}
	checkIfInOrder(item: Coupon): boolean {
		return !!(this.order?.coupon || []).find(it => it?._id === item._id);
	}
}
