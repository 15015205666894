import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { environment } from '../../../environments/environment';
import { getPrice } from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-overlay',
	templateUrl: './article-option-group-overlay.component.html',
	styleUrls: ['article-option-group-overlay.component.scss'],
})
export class ArticleOptionGroupOverlayComponent {
	@Input() optionGroup: OptionGroup;
	@Input() currency: string;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;

	OrderType = OrderType;
	selectedArticle: Article;

	constructor() {}

	get price() {
		return getPrice(
			this.optionGroup.articles.find(article => getPrice(article, OrderType.PREORDER, this.preorderType) > 0),
			OrderType.PREORDER,
			this.preorderType
		);
	}

	select(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.quantity = 1;
		articleOption.group = this.optionGroup._id;
		this.selection.emit(articleOption);
	}

	selectArticle(article: Article) {
		this.selectedArticle = article;
		this.select(article);
	}
}
