import { Component, Input, OnChanges } from '@angular/core';
import { HomePage } from '../../pages/home/home.page';
import { MenuPage } from '../../pages/menu/menu.page';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import Address from '../../../smoothr-web-app-core/models/Address';
import { HomePopoverComponent } from '../home-popover/home-popover.component';
import { PopoverController } from '@ionic/angular';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-toolbar-small',
	templateUrl: './toolbar-small.component.html',
	styleUrls: ['toolbar-small.component.scss'],
})
export class ToolbarSmallComponent {
	environment = environment;
	@Input()
	address: Address;
	@Input()
	preorderType: PreorderType;
	@Input()
	venue: Venue;
	@Input()
	order: Order;
	constructor(
		private repository: RepositoryService,
		private popoverCtrl: PopoverController,
		private router: Router
	) {}

	home() {
		if (!this.address) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}

	async presentHomePopover(ev: any) {
		const popover = await this.popoverCtrl.create({
			component: HomePopoverComponent,
			event: ev,
			translucent: true,
			mode: 'ios',
			cssClass: 'home-popover',
		});
		return await popover.present();
	}
}
