import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { IonSlides, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { MapsUtils } from 'src/app/utils/maps';
import { AllowGpsModalComponent, AllowGpsModalResult } from '../../../app/components/allow-gps-modal/allow-gps-modal.component';
import { CheckDeliveryRadiusModalComponent } from '../../../app/components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import { DeliveryNotAvailableModalComponent } from '../../../app/components/delivery-not-available-modal/delivery-not-available-modal.component';
import { DeliveryNotAvailableAction } from '../../../app/enums/DeliveryNotAvailableAction';
import { Api } from '../../../smoothr-web-app-core/api/api';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import Address from '../../../smoothr-web-app-core/models/Address';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { TimeUtils } from '../../../smoothr-web-app-core/utils/time-utils';
import { coverFlow, sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import { ParkCollectSheetComponent } from '../../../app/components/park-collect-sheet/park-collect-sheet.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import { calculateGeoDistance } from 'src/smoothr-web-app-core/utils/maps-utils';
import Geocoder = google.maps.Geocoder;
import LatLng = google.maps.LatLng;
import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
	selector: 'app-map-venue',
	templateUrl: './map-venue.component.html',
	styleUrls: ['./map-venue.component.scss'],
})
export class MapVenueComponent extends RepositoryDirective implements OnInit {
	@Input() showSearchInput = false;
	@Input() showVenueList = true;
	@Input() venueToFirstPositionShortId = '';
	@ViewChild('mapContainer', { static: true })
	mapElement: ElementRef;
	// @ViewChild(IonInput, { static: true })
	// inputField: IonInput;
	@ViewChild('venueList', { static: false }) venueList;

	@ViewChild(IonSlides, { static: true })
	slides: IonSlides;
	map: Map;
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{ visibility: 'off' }],
			},

			{ elementType: 'geometry', stylers: [{ color: '#1f1f1e' }] },
			{ elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
			{ elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
			{
				featureType: 'administrative.locality',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#5b5b5b' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#F1EBE0' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ color: '#1A1A1A' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#F1EBE0' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#1A1A1A' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#1A1A1A' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#585858' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#292929' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#1a1a1a' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#585858' }],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [{ color: '#1A1A1A' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#F1EBE0' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#292929' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#1A1A1A' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#1A1A1A' }],
			},
		],
	};
	clusterer: MarkerClusterer;
	loading = false;
	loadingAddress = false;
	predictions: Address[] = [];
	private _showPredictions = false;
	showHint = false;
	searchTerm: string;
	allVenues: Venue[] = [];
	localVenues: Venue[] = [];
	selectedVenueForDelivery: Venue;
	showMap = true;
	slidesOpts = {
		slidesPerView: 1,
		coverflowEffect: {
			rotate: 0,
			stretch: 15,
			depth: 10,
			modifier: 2,
		},
		on: coverFlow,
	};
	showInputForAddress = false;
	showListOfVenues = true;
	selectedVenue: Venue;
	MapsUtils = MapsUtils;
	@Input() selectFirstVenue: boolean = false;
	public articleIdFromUrl = '';
	public categoryIdFromUrl = '';
	public tagFromUrl = '';
	allVenuesFavourite: Venue[] = [];
	favouritesVenues: Venue[] = [];
	preorderTypeQueryParams: PreorderType = null;
	constructor(
		private snackbarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController,
		private navService: NavigationService,
		private route: ActivatedRoute,
		private geolocation: Geolocation
	) {
		super(repository);
	}

	get relevantVenues(): Venue[] {
		return this.preorderTypeQueryParams
			? this.allVenues.filter(it => {
					if (this.preorderTypeQueryParams === PreorderType.TAKE_AWAY) {
						return it.preorderTakeAway;
					}
					if (this.preorderTypeQueryParams === PreorderType.DELIVERY) {
						return it.deliveryEnabled;
					}
					if (this.preorderTypeQueryParams === PreorderType.PARK_COLLECT) {
						return it.preorderParkCollect;
					}
			  })
			: this.allVenues;
	}

	ngOnInit() {
		super.ngOnInit();
		this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		// tslint:disable-next-line:no-unused-expression
		this.route.queryParams.subscribe(params => {
			if (params?.preorderType) {
				this.preorderTypeQueryParams = params?.preorderType;
			}
			if (params?.itemId) {
				this.articleIdFromUrl = params.itemId;
			}
			if (params?.categoryId) {
				this.categoryIdFromUrl = params?.categoryId;
			}
			if (params?.tag) {
				this.tagFromUrl = params?.tag;
			}
		});
		this.loadFavouriteVenue();
		console.log('here', this.map);
	}
	async loadFavouriteVenue() {
		this.repository.favorite.subscribe(async v => {
			const allVenuesData = this.allVenues;
			this.favouritesVenues = (v ?? [])
				.map(it => {
					const foundVenue = allVenuesData.find(venue => venue?._id === it);
					if (foundVenue) {
						return foundVenue;
					} else null;
				})
				.filter(it => !!it);
		});
	}
	async resetMap() {
		console.log('HERE', this.allVenues);
		if (this.allVenues.length === 0) {
			try {
				this.allVenues = (await Api.getAllVenues()).data
					.map(ven => {
						try {
							ven.openingHours = TimeUtils.sanitizeHours(ven.openingHours);
							ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
						} catch (e) {
							console.error({
								message: 'Error while sanitizing hours ' + e,
								venue: ven.name + ' ' + ven.readableId,
								openingHours: ven.openingHours,
								deliveryHours: ven.deliveryHours,
							});
						}
						return ven;
					})
					.filter(ven => venueAcceptsOrders(ven, PreorderType.DELIVERY) || venueAcceptsOrders(ven, PreorderType.TAKE_AWAY));
				if (this.venueToFirstPositionShortId) {
					const findedObje = this.allVenues.find(item => item.readableId == this.venueToFirstPositionShortId);
					this.allVenues = this.allVenues.filter(item => item.readableId !== this.venueToFirstPositionShortId);
					this.allVenues.unshift(findedObje);
				}

				// if (this.allVenues.length > 0 && this.selectFirstVenue) {
				// 	this.selectedVenue = this.allVenues[0];
				// } else {
				// 	this.selectedVenue = null;
				// }
			} catch (e) {}
		}
		await this.setupMap(this.selectedVenue);
	}

	async setupMap(selectedVenue: Venue) {
		if (this.loading && !this.map) {
			return;
		}
		this.selectedVenue = selectedVenue;
		this.loading = true;
		this.cdr.detectChanges();
		this.clusterer = MapsUtils.addVenuesToMap(this.clusterer, selectedVenue, this.relevantVenues, this.map, venue =>
			this.setupMap(venue)
		);
		if (selectedVenue && selectedVenue.location && selectedVenue.location.coordinates) {
			const selectedIndex = this.relevantVenues.findIndex(ven => ven._id === selectedVenue._id);
			// wait until slides rendered
			await this.slides.length();
			await this.slides.slideTo(selectedIndex);
		}
		if (selectedVenue) {
			const selectedIndex = this.relevantVenues.findIndex(ven => ven._id === selectedVenue._id);
			this.scrollToVenue(selectedIndex ?? 0);
		}

		this.loading = false;
		this.cdr.detectChanges();
	}

	async loadVenues(address: Address) {
		console.log('LOADVENUES');
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		this.localVenues = [];
		try {
			this.localVenues = await this.repository.getVenuesByAddress(null);
			this.searchTerm = MapsUtils.addressToString(address);
			if (this.localVenues.length === 0 && !this.selectedVenueForDelivery) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.no_venues_in_address', {
						title: this.searchTerm,
					}),
					null,
					{
						duration: 2000,
					}
				);
				this.loading = false;
				// await this.resetSearch();
				this.cdr.detectChanges();
				return;
			}
			if (this.selectedVenueForDelivery) {
				const deliveryVenues = this.localVenues.filter(ven => venueAcceptsOrders(ven, PreorderType.DELIVERY));
				const selectedDeliveryVenue = deliveryVenues.find(ven => ven._id === this.selectedVenueForDelivery._id);
				if (selectedDeliveryVenue) {
					await this.selectVenue(selectedDeliveryVenue, PreorderType.DELIVERY);
				} else {
					const choice = await DeliveryNotAvailableModalComponent.show(this.modalCtrl);
					switch (choice) {
						case DeliveryNotAvailableAction.DECLINE:
							this.localVenues = deliveryVenues;
							if (deliveryVenues.length === 0) {
								this.repository.address.next(null);
							} else {
								this.showHint = true;
							}
							await this.setupMap(null);
							break;
						case DeliveryNotAvailableAction.TAKE_AWAY:
							await this.selectVenue(this.selectedVenueForDelivery, PreorderType.TAKE_AWAY);
							break;
					}
				}
				this.selectedVenueForDelivery = null;
				return;
			}
			this.selectedVenue = null;
			this.loading = false;
			await this.setupMap(this.selectedVenue);
		} catch (e) {
			console.error(e);
		}
		if (this.localVenues.length > 0 && this.selectFirstVenue) {
			this.selectedVenue = this.localVenues[0];
		} else {
			this.selectedVenue = null;
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async onSlideChange() {
		let index = await this.slides.getActiveIndex();
		if (index >= this.relevantVenues.length) {
			index = 0;
		}
		await this.setupMap(this.relevantVenues[index]);
	}
	selectVenueToDelivery(venue: Venue) {
		this.selectedVenueForDelivery = venue;
	}
	selectVenueFunc(venue: Venue) {
		this.selectedVenue = venue;
		this.setupMap(venue);
	}

	async selectVenue(venue: Venue, preorderType, attempt: number = 0, prevError: any = null) {
		if (
			preorderType === PreorderType.DELIVERY &&
			venue.distance > venue.deliveryRadius &&
			venue.deliveryByRadius &&
			!venue.isPostalDelivery
		) {
			this.snackbarCtrl.open(this.translate.instant('map_page.venue_does_not_delivery_to_address'), null, {
				duration: 2000,
			});
			return;
		}
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}
		if (this.loading) {
			return;
		}
		console.log('here');

		this.cdr.detectChanges();
		this.loading = true;
		await this.repository.getVenue(venue._id);

		if (preorderType === PreorderType.DELIVERY) {
			try {
				const geolocationResult = await AllowGpsModalComponent.show(this.modalCtrl);
				console.log(geolocationResult);
				if (geolocationResult === AllowGpsModalResult.ALLOW) {
					const address = await this.getLocation();
					console.log(address);
					if (address) {
						console.log(
							this.venue.deliveryByRadius,
							calculateGeoDistance(address.lat, address.lng, venue.location.coordinates[1], venue.location.coordinates[0]),
							venue.deliveryRadius,
							venue.isPostalDelivery,
							venue.deliveryPostalCodes.indexOf(address.postalCode)
						);

						const delivers =
							(this.venue.deliveryByRadius &&
								calculateGeoDistance(
									address.lat,
									address.lng,
									venue.location.coordinates[1],
									venue.location.coordinates[0]
								) <= venue.deliveryRadius) ||
							!!(venue.isPostalDelivery && venue.deliveryPostalCodes.indexOf(address.postalCode));
						console.log(delivers);
						if (!delivers) {
							const reuslt = await InfoModalComponent.show(this.modalCtrl, {
								info: this.translate.instant('map_page.venue_does_not_delivery_to_address'),
								closeButton: this.translate.instant('add_points.close'),
							});
							this.loading = false;
							return;
						} else {
							this.repository.address.next(address);
							// if (this.order) {
							// 	this.repository.updateOrder(
							// 		this.venue,
							// 		this.order,
							// 		address,
							// 		OrderType.PREORDER,
							// 		PreorderType.DELIVERY
							// 	);
							// } else {
							this.repository.venue.next(await this.repository.getVenue(venue._id));
							this.repository.createOrder(venue, address, OrderType.PREORDER, PreorderType.DELIVERY);
							// }

							await sleep(500);
							const navigationUrl = this.router.navigateByUrl(this.navigationToMenuPageWithQueryParams(), {
								replaceUrl: true,
							});
							this.loading = false;
							return;
						}
					} else {
						await InfoModalComponent.show(this.modalCtrl, {
							info: this.translate.instant('map_page.access'),
							closeButton: this.translate.instant('add_points.close'),
						});
						this.loading = false;
						return;
					}
				} else {
					const result: {
						venue: Venue;
						address: Address;
						preorderType: PreorderType;
					} = await CheckDeliveryRadiusModalComponent.show(this.modalCtrl, this.venue);
					if (result?.preorderType) {
						await this.repository.getVenue(venue._id);
						this.repository.createOrder(venue, this.address, OrderType.PREORDER, PreorderType.TAKE_AWAY);
						await sleep(500);
						const navigationUrl = this.router.navigateByUrl(this.navigationToMenuPageWithQueryParams(), {
							replaceUrl: true,
						});
						this.loading = false;
						return;
					}
					if (result) {
						console.log(result);
						this.repository.address.next(result.address);
						// if (this.order) {
						// 	this.repository.updateOrder(
						// this.venue,
						// this.order,
						// this.address,
						// OrderType.PREORDER,
						// PreorderType.DELIVERY
						// );
						// } else {
						this.repository.venue.next(await this.repository.getVenue(result.venue._id));
						this.repository.createOrder(venue, this.address, OrderType.PREORDER, PreorderType.DELIVERY);
						// }
						await sleep(500);

						const navigationUrl = this.router.navigateByUrl(this.navigationToMenuPageWithQueryParams(), {
							replaceUrl: true,
						});
						this.loading = false;
					}
					this.loading = false;
					this.cdr.detectChanges();
				}
				return;
			} catch (e) {
				console.log(e);
				this.loading = false;
				return;
			}
		}
		if (preorderType === PreorderType.PARK_COLLECT) {
			preorderType = await ParkCollectSheetComponent.show(this.modalCtrl, venue);
		}
		try {
			// if (this.order) {
			// 	this.repository.updateOrder(
			// 		this.venue,
			// 		this.order,
			// 		this.address,
			// 		OrderType.PREORDER,
			// 		preorderType
			// 	);
			// } else {

			this.repository.createOrder(venue, this.address, OrderType.PREORDER, preorderType);
			await sleep(1500);
			// }
			this.router.navigateByUrl(this.navigationToMenuPageWithQueryParams(), {
				replaceUrl: true,
			});
			this.loading = false;
			this.cdr.detectChanges();
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
			this.loading = false;
		}
	}
	async getLocation() {
		try {
			let options = {
				timeout: 10000,
				enableHighAccuracy: true,
				maximumAge: 3600,
			};

			const geoposition = await this.geolocation.getCurrentPosition(options);
			const result = await new Geocoder().geocode({
				location: new LatLng(geoposition.coords.latitude, geoposition.coords.longitude),
			});

			if (result.results.length > 0) {
				console.log(result, MapsUtils.placeToAddressGoogle(result.results[0]));
				return MapsUtils.placeToAddressGoogle(result.results[0]);
			} else {
				return null;
			}
		} catch (e) {
			console.log(e);
			return null;
		}
	}
	async loadPlace(pred) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		// await this.fillInPlace(pred);
		this.loading = false;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		// tslint:disable-next-line:no-unused-expression
		new Promise(async () => {
			if (this.address) {
				await this.resetMap();
			} else {
				await this.resetMap();
				// this.resetSearch();
			}
		});
	}

	setShowMap(value: boolean) {
		this.showMap = value;
		this.cdr.detectChanges();
	}
	async changesAddress(address: Address) {
		this.repository.address.next(address);
		await this.onSlideChange();
	}
	navigationToMenuPageWithQueryParams() {
		const url = '/' + NAVIGATIONURLS.menu();
		if (this.articleIdFromUrl) {
			return url + `?itemId=${this.articleIdFromUrl}`;
		}
		if (this.categoryIdFromUrl) {
			return url + `?categoryId=${this.categoryIdFromUrl}`;
		}
		if (this.tagFromUrl) {
			return url + `?tag=${this.tagFromUrl}`;
		}
		return url;
	}
	private async scrollToVenue(index: number) {
		await this.venueList.scrollToPoint(0, this.venueList.el.children[index].offsetTop - 50, 1000);
	}
}
