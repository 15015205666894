import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';
import { getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { environment } from '../../../environments/environment';
import { getAvailability } from '../../../smoothr-web-app-core/utils/utils';
import { ModalController } from '@ionic/angular';
import { AllergenInfoModalComponent, Nutrition } from '../allergen-info-modal/allergen-info-modal.component';

@Component({
	selector: 'app-article-option-group-checkbox',
	templateUrl: './article-option-group-checkbox.component.html',
	styleUrls: ['article-option-group-checkbox.component.scss'],
})
export class ArticleOptionGroupCheckboxComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;
	@Output() openInfo = new EventEmitter<Article>();

	ot = OrderType;
	di = DisplayIdentifier;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;

	constructor(private modalCtrl: ModalController) {}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	onSelectionChange(article: Article, quantity: number) {
		console.log(this.optionGroup);
		console.log('on selection change', article.name.de, quantity);
		if (this.isDisabled(article)) {
			return;
		}
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = quantity;
		this.selection.emit(articleOption);
	}

	toggle(option: Article) {
		this.onSelectionChange(option, this.selected.map(ao => ao.article?._id).indexOf(option?._id) < 0 ? 1 : -1);
	}

	isDisabled(option: Article): boolean {
		return option.requirements && option.requirements.min > 0 && this.isOptionSelected(option);
	}

	isOptionSelected(option: Article): boolean {
		return this.selected.find(sel => sel.article._id === option._id && sel.group === this.optionGroup._id) !== undefined;
	}

	async openInfoClick(option: Article) {
		await AllergenInfoModalComponent.show(this.modalCtrl, option);
	}
	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article) {
		if (!article?.nutritions) {
			return null;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
}
