import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['toolbar.component.scss'],
})
export class ToolbarComponent extends RepositoryDirective implements OnInit {
	@Input() preorderType: PreorderType;
	@Input() title = '';
	@Output() arrowClick = new EventEmitter<void>();
	@Input() emitArrowClick = false;
	@Input() showArrow = true;
	@Input() showMenu = false;
	@Input() order: Order;
	PreorderType = PreorderType;
	environment = environment;

	constructor(repository: RepositoryService) {
		super(repository);
	}

	get type(): PreorderType {
		return this.order?.preorder?.type ? this.order.preorder.type : this.preorderType;
	}

	ngOnInit() {
		super.ngOnInit();
	}

	onArrowClick() {
		if (this.emitArrowClick) {
			this.arrowClick.emit();
		} else {
			window.history.back();
		}
	}
}
