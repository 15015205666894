import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-favourite-restaurant',
	templateUrl: './favourite-restaurant.component.html',
	styleUrls: ['./favourite-restaurant.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouriteRestaurantComponent {
	constructor(private modalCtrl: ModalController) {}

	venues: Venue[] = [];
	favouriteVenue: Venue[] = [];

	static async show(modalCtrl: ModalController, venuesMok, favouriteVenue): Promise<Venue[]> {
		const modal = await modalCtrl.create({
			cssClass: 'favourite-venue-modal',
			component: FavouriteRestaurantComponent,
			componentProps: {
				venues: venuesMok,
				favouriteVenue: favouriteVenue,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();

		const result = (await modal.onDidDismiss()).data;
		return result;
	}

	onBackClick() {
		this.modalCtrl.dismiss(this.favouriteVenue);
	}

	isFavourite(id: string) {
		return (this.favouriteVenue ?? []).some(venue => venue._id === id);
	}

	addFavourite(venue: Venue) {
		this.favouriteVenue.push(venue);
	}

	removeFavourite(id: string) {
		this.favouriteVenue = (this.favouriteVenue ?? []).filter(venueId => venueId?._id !== id);
	}

	trackBy(index: number) {
		return index;
	}
}
