import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from '../../../smoothr-web-app-core/api/api';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from '../../../environments/environment';
import { firstOfObservable, numberD, numberToCurrency, sleep } from '../../../smoothr-web-app-core/utils/utils';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import Order from '../../../smoothr-web-app-core/models/Order';
import Payment from 'src/smoothr-web-app-core/models/Payment';
import { PaymentMethod } from 'src/smoothr-web-app-core/enums/PaymentMethod';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';

@Component({
	selector: 'app-payment-success',
	templateUrl: './payment-success.page.html',
	styleUrls: ['payment-success.page.scss'],
})
export class PaymentSuccessPage implements OnInit {
	static url = 'payment/success';

	static urlWithPaymentParam = 'payment/success/:payment';
	OrderUtils = OrderUtils;
	pt = PreorderType;
	orderVenue: Venue;
	orders: Order[] = [];
	error: any;
	payment: Payment;
	environment = environment;
	paymentMethod = PaymentMethod;
	numberToCurrency = numberToCurrency;
	numberD = numberD;
	points = 18;
	constructor(
		private translate: TranslateService,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private navService: NavigationService
	) {}

	static async navigate(router: Router, orders: string[], payment: string) {
		await router.navigate([NAVIGATIONURLS.paymentSuccess() + '/' + payment], {
			queryParams: {
				orders,
			},
		});
	}

	ngOnInit(): void {
		this.loadParams();
	}

	async loadParams() {
		try {
			let orderIds: string[] = [];
			try {
				const params = await firstOfObservable(this.route.params);
				if (params.payment) {
					const payment = await this.loadPayment(params.payment);
					orderIds = payment.orders;
					this.payment = payment;
				}

				this.repository._order.orderedArticles = [];
				this.repository._order.coupon = [];
				this.repository.order.next(this.repository._order);
				if (
					this.repository._customerAuth &&
					this.repository._customerAuth?.customer &&
					!this.repository._customerAuth?.customer?.isAnonymous &&
					this.repository._customerAuth?.customer?.email
				) {
					await sleep(500);
					this.repository.reloadCustomer();
				}
			} catch (e) {
				const queryParams = await firstOfObservable(this.route.queryParams);
				if (queryParams.orders) {
					orderIds = [queryParams.orders];
				}
			}
			if (orderIds.length < 1) {
				this.snackbarCtrl.open(this.translate.instant('payment_success_page.payment_error'), null, {
					duration: 2000,
				});
			}
			this.orders = await this.loadOrders(orderIds);
		} catch (e) {
			console.error(e);
			this.error = e ? e : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(this.error, null, {
				duration: 2000,
			});
		}
	}

	async loadPayment(paymentId: string, attempt: number = 0, error = null): Promise<Payment> {
		if (attempt > 5) {
			throw error;
		}
		try {
			return (await Api.getPayment(paymentId)).data;
		} catch (e) {
			return await this.loadPayment(paymentId, attempt + 1, e);
		}
	}

	async loadOrders(orderIds: string[]): Promise<Order[]> {
		const orders = [];
		for (const order of orderIds) {
			orders.push(await this.reloadOrder(order));
		}
		return orders;
	}

	async reloadOrder(orderId: string, attempt: number = 0, error = null): Promise<Order> {
		this.error = null;

		if (attempt > 10) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000,
			});
			return;
		}
		if (attempt > 0) {
			await sleep(1000);
		}
		try {
			const orderResponse = await Api.getOrder(orderId);
			const order = orderResponse.data;
			order.orderAt = moment(order.orderAt);
			if (!order.isPayed) {
				return await this.reloadOrder(orderId, attempt + 1, this.translate.instant('payment_success_page.please_reload'));
			}
			try {
				if (this.orderVenue?._id !== order.venue) {
					this.orderVenue = (await Api.getLazyVenue(order.venue)).data;
				}
				return order;
			} catch (e) {
				console.error(e);
				return await this.reloadOrder(orderId, attempt + 1, error ? error : e);
			}
		} catch (e) {
			console.error(e);
			return await this.reloadOrder(orderId, attempt + 1, e);
		}
	}

	home() {
		this.repository.order.next(null);
		this.navService.home();
	}
	navigateLoyalty() {
		this.repository.order.next(null);

		this.navService.loyalty();
	}
	checkPrice(articleGroup: ArticleGroup) {
		return Number(articleGroup?.totalPrice) > 0 && !articleGroup?.redeemPointsWithPiggy;
	}
	pointsReedemed(order: Order) {
		return order.redeemedPiggyPoints > 0 ? order.redeemedPiggyPoints + 'Ash Points' : '';
	}

	totalSum(currency: string) {
		return numberToCurrency(numberD(this.payment.totalSum), currency);
	}
	getQrCode(order: Order) {
		if (order?._id) {
			return 'smoothr://' + order?._id;
		} else {
			return '';
		}
	}
}
