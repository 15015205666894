import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import { MapPage } from '../map/map.page';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { BehaviorSubject } from 'rxjs';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { isVenueOpen, sleep, venueAcceptsOrders } from 'src/smoothr-web-app-core/utils/utils';
import { WorkingHoursModalComponent } from 'src/app/components/working-hours-modal/working-hours-modal.component';
import { ModalController } from '@ionic/angular';
import Address from 'src/smoothr-web-app-core/models/Address';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { CheckDeliveryRadiusModalComponent } from 'src/app/components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import { AllowGpsModalComponent } from 'src/app/components/allow-gps-modal/allow-gps-modal.component';
import { ParkCollectSheetComponent } from 'src/app/components/park-collect-sheet/park-collect-sheet.component';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SpeisekarteModalComponent } from 'src/app/components/speisekarte-modal/speisekarte-modal.component';

enum Language {
	GreatBritain = 'en',
	German = 'de',
}

type Slides = { title: string; info: string; img: string };

@Component({
	selector: 'app-home',
	templateUrl: './home.page.theash.html',
	styleUrls: ['./home.page.theash.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/table/:tableNumber';

	environment = environment;
	preorderTypes = [PreorderType.TAKE_AWAY, PreorderType.DELIVERY, PreorderType.PARK_COLLECT];
	menu_recomendation = [
		{
			title: this.translate.instant('home_page.reccomendations.first.title'),
			subtitle: this.translate.instant('home_page.reccomendations.first.subtitle'),
			img: '/assets/home/recom_img.png',
			description: this.translate.instant('home_page.reccomendations.first.description'),
		},
		{
			title: this.translate.instant('home_page.reccomendations.second.title'),
			subtitle: this.translate.instant('home_page.reccomendations.second.subtitle'),
			img: '/assets/home/recom_menu.png',
			description: this.translate.instant('home_page.reccomendations.second.description'),
		},
		{
			title: this.translate.instant('home_page.reccomendations.third.title'),
			subtitle: this.translate.instant('home_page.reccomendations.third.subtitle'),
			img: '/assets/home/recom_img.png',
			description: this.translate.instant('home_page.reccomendations.third.description'),
		},
	];
	preorderTypeEnum = PreorderType;
	languageEnum = Language;
	specialsCategory: ArticleCategory = null;
	loadingSpecialCategory = false;
	loading = false;
	active: any = {};
	loading$ = new BehaviorSubject(false);
	public relevantVenues: Venue[] = [];
	isVenueOpen = isVenueOpen;

	public config: SwiperOptions = {
		loop: true,
		speed: 400,
		navigation: true,
		grabCursor: true,
		// slidesPerView: 3,
		effect: 'coverflow',
		coverflowEffect: {
			depth: 0,
			rotate: 0,
			scale: 0.9,
			stretch: -2,
			modifier: 1.1,
		},
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 1,
			},
			// when window width is >= 640px
			640: {
				slidesPerView: 3,
			},
		},
	};

	slides: Slides[] = [
		{
			title: 'home_page.slider.1.title',
			info: 'home_page.slider.1.info',
			img: '../../../assets/theash/slider_img/1.webp',
		},
		{
			title: 'home_page.slider.2.title',
			info: 'home_page.slider.2.info',
			img: '../../../assets/theash/slider_img/2.webp',
		},
		{
			title: 'home_page.slider.3.title',
			info: 'home_page.slider.3.info',
			img: '../../../assets/theash/slider_img/3.webp',
		},
		{
			title: 'home_page.slider.4.title',
			info: 'home_page.slider.4.info',
			img: '../../../assets/theash/slider_img/4.webp',
		},

		{
			title: 'home_page.slider.1.title',
			info: 'home_page.slider.1.info',
			img: '../../../assets/theash/slider_img/1.webp',
		},
		{
			title: 'home_page.slider.2.title',
			info: 'home_page.slider.2.info',
			img: '../../../assets/theash/slider_img/2.webp',
		},
		{
			title: 'home_page.slider.3.title',
			info: 'home_page.slider.3.info',
			img: '../../../assets/theash/slider_img/3.webp',
		},
		{
			title: 'home_page.slider.4.title',
			info: 'home_page.slider.4.info',
			img: '../../../assets/theash/slider_img/4.webp',
		},
	];

	speisekarteItems = [
		{
			title: 'Aachen',
			link: '../../../assets/speisekarten_pdf/ASH MENU FULL.pdf',
		},
		{
			title: 'Bremen',
			link: '../../../assets/speisekarten_pdf/ASH_MENU_23_CITY.pdf',
		},
		{
			title: 'Bochum',
			link: '../../../assets/speisekarten_pdf/ASH_MENU_23_CITY.pdf',
		},
		{
			title: 'Dortmund',
			link: '../../../assets/speisekarten_pdf/ASH MENU FULL.pdf',
		},
		{
			title: 'Düsseldorf',
			link: '../../../assets/speisekarten_pdf/ASH_MENU_23_METRO_DUS_05_23.pdf',
		},
		{
			title: 'Essen',
			link: '../../../assets/speisekarten_pdf/ASH MENU FULL.pdf',
		},
		{
			title: 'Frankfurt',
			link: '../../../assets/speisekarten_pdf/ASH_MENU_23_METRO_DUS_05_23.pdf',
		},
		{
			title: 'Köln Hahnenstraße',
			link: '../../../assets/speisekarten_pdf/ASH MENU FULL.pdf',
		},
		{
			title: 'Köln MesseCity',
			link: '../../../assets/speisekarten_pdf/ASH MENU FULL.pdf',
		},
		{
			title: 'München',
			link: '../../../assets/speisekarten_pdf/ASH_MENU_23_METRO_DUS_05_23.pdf',
		},
		{
			title: 'Oberhausen',
			link: '../../../assets/speisekarten_pdf/ASH_MENU_23_METRO_DUS_05_23.pdf',
		},
		{
			title: 'Troisdorf',
			link: '../../../assets/speisekarten_pdf/ASH MENU FULL.pdf',
		},
	];

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		public translate: TranslateService,
		private navService: NavigationService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private navigationService: NavigationService,
		private snackbarCtrl: MatSnackBar,
		private renderer: Renderer2
	) {
		super(repository);
	}

	@ViewChild('swiper') swiper: ElementRef | undefined;
	@ViewChild('swiperWrapper') swiperWrapper: ElementRef | undefined;

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.next(null);
			repository.order.next(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
			repository.resetVenue();
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe((params: any) => {
			if (params && params?.venueId) {
				this.loadSelectedVenue(params?.venueId);
			} else {
				// this.locateAndShowStores(PreorderType.INSIDE);
				this.updateUser();
			}
		});
		this.loadVenues();
	}
	async ngAfterViewInit() {
		const wrapperElement = this.swiperWrapper.nativeElement;
		this.renderer.setStyle(wrapperElement, 'width', '99%');
		this.renderer.setStyle(wrapperElement, 'max-width', '1001px');
		await sleep(1200);
		this.renderer.setStyle(wrapperElement, 'width', '100%');
		this.renderer.setStyle(wrapperElement, 'max-width', '1000px');
	}
	ionViewDidEnter() {
		this.repository.order.next(null);
		// this.repository.previousPreorder.emit(null);
		this.repository.verifiedOrder.emit(null);
		this.repository.address.next(null);
		this.repository.payment.emit(null);
		this.repository.resetVenue();
	}
	async loadVenues() {
		try {
			this.loadingSpecialCategory = true;
			const venue = await this.repository.getVenueAndLoadAllCategories(environment.customerGroup + '_master');
			this.specialsCategory = venue.articleCategories.find(
				it => it?.tags && it?.tags?.length > 0 && it?.tags?.find(tag => tag.identifier === 'specials')
			);
			this.loadingSpecialCategory = false;
		} catch (e) {
			this.loadingSpecialCategory = false;
		}
	}
	async openSpecialsCategory(category: ArticleCategory) {
		this.navService.map({ tag: 'specials' });
	}
	async loadSelectedVenue(venueId: string) {
		// console.log('loadSelectedVenue');
		if (this.relevantVenues.length === 0) {
			this.loading$.next(true);
			const venue = await this.repository.getVenue(venueId);
			if (venue) {
				this.relevantVenues = [venue];
			}
			// await this.selectVenue(venue);
			this.loading$.next(false);
		}
	}
	async locateAndShowStores(preorderType: PreorderType) {
		console.log('locateAndShowStores');

		if (!this.venue) {
			this.loading$.next(true);

			const venues = (await Api.getAllVenues()).data.filter(it => venueAcceptsOrders(it, PreorderType.TAKE_AWAY));
			if (venues.length > 0) {
				await this.repository.getVenue(venues[0]?._id);
			}
			sleep(100);
			if (!this.repository._order || this.repository?._order?.coupon == null) {
				this.repository.createOrder(venues[0], null, OrderType.PREORDER, PreorderType.TAKE_AWAY);
			}
			this.loading$.next(false);
		}
	}

	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}

	goBack() {
		this.navigationService.navigateToUrlWithParams(NAVIGATIONURLS.home(), null);
	}
	isVenueOpenFunc(venue: Venue) {
		return this.isVenueOpen(venue) ? this.translate.instant('venue_suggestion.open') : this.translate.instant('venue_suggestion.close');
	}

	async updateUser() {
		if (this.customer && !this.customer?.isAnonymous && this.customer.email) {
			try {
				await sleep(500);
				const user = await this.repository.reloadCustomer();
			} catch (e) {}
		}
	}

	async navigateMap(preorderType: PreorderType = null) {
		if (preorderType) {
			this.navService.map({ preorderType });
		} else {
			this.navService.map();
		}
	}
	async openBracher() {
		window.open('https://www.the-ash.com/brunchen', '_blank');
	}
	async openButcher() {
		window.open('https://ash-butchershop.com/', '_blank');
	}
	async openLoyalty() {
		this.navService.loyalty();
	}
	async openCoupons() {
		this.navService.coupons();
	}
	async navigateButcher() {
		window.open('https://ash-butchershop.com/', '_blank');
	}
	async navigateTischReserv() {
		window.open('https://www.the-ash.com/restaurants', '_blank');
	}
	async goToSignIn() {
		this.navService.signIn();
	}
	apple() {
		window.open('https://apps.apple.com/us/app/the-ash-restaurant-bar/id1667233897', '_blank');
	}
	google() {
		window.open('https://play.google.com/store/apps/details?id=de.sterlix.smoothr.theash&pli=1', '_blank');
	}

	openSpeisekarte() {
		SpeisekarteModalComponent.show(this.modalCtrl, this.speisekarteItems);
	}
}
