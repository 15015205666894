import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import Order from 'src/smoothr-web-app-core/models/Order';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { defaultsToArticleOption, getPrice, numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-show-recomendation-articles',
	templateUrl: './show-recomendation-articles.component.html',
	styleUrls: ['./show-recomendation-articles.component.scss'],
})
export class ShowRecomendationArticlesComponent implements OnInit {
	articlesRecomendationList: Article[];
	order: Order;
	numberToCurrency = numberToCurrency;
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, order: Order, articlesRecomendationList: Article[]): Promise<Article> {
		const modal = await modalCtrl.create({
			component: ShowRecomendationArticlesComponent,
			componentProps: {
				articlesRecomendationList,
				order,
			},
			cssClass: 'show-recomendation auto-height',
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}
	ngOnInit() {}
	showPrice(article: Article): number {
		if (article) {
			if (article.isFromPrice) {
				const articleGroup = new ArticleGroup();
				articleGroup.article = article;
				articleGroup.groups = defaultsToArticleOption(
					article,
					[],
					article.defaults,
					OrderType.PREORDER,
					this.order?.preorder?.type
				);
				articleGroup.quantity = 1;
				return OrderUtils.totalPrice(articleGroup, this.order?.type, this.order?.preorder?.type);
			} else {
				return getPrice(article, this.order?.type, this.order?.preorder?.type);
			}
		} else {
			return 0;
		}
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	select(article: Article) {
		this.modalCtrl.dismiss(article);
	}
}
