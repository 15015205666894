import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

export const NAVIGATIONURLS = {
	tos: () => 'tos',
	auth: () => 'auth',
	map: () => 'map',
	home: () => 'home',
	menu: () => 'menu',
	order: () => 'order',
	signIn: () => 'sign-in',
	signUp: () => 'sign-up',
	myData: () => 'account-data',
	forgotPassword: () => 'forgot-password',
	privacy: () => 'privacy',
	account: () => 'auth/account',
	loyalty: () => 'loyalty',
	coupons: () => 'coupons',
	faq: () => 'faq',
	myOrders: () => 'my-orders',
	impressum: () => 'impressum',
	maintenance: () => 'maintenance',
	emailAction: () => 'email-action',
	paymentFail: () => 'payment/fail',
	selectVenue: () => 'select-venue',
	confirmEmail: () => 'confirm-email',
	paymentCancel: () => 'payment/cancel',
	paymentSuccess: () => 'payment/success',
	paymentFailPaymentId: (paymentId?: string) => `payment/fail/${paymentId}`,
	paymentCancelPaymentId: (paymentId?: string) => `payment/cancel/${paymentId}`,
	paymentSuccessPaymentId: (paymentId?: string) => `payment/success/${paymentId}`,
};
export const navPaymentSuccess = async (router: Router, order: string, payment: string) => {
	await router.navigate([NAVIGATIONURLS.paymentSuccessPaymentId(payment)], {
		queryParams: {
			order,
		},
	});
};
@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor(private router: Router) {}
	async menu() {
		await this.router.navigateByUrl(NAVIGATIONURLS.menu());
	}

	async home() {
		await this.router.navigate([NAVIGATIONURLS.home()], {
			skipLocationChange: true,
			replaceUrl: false,
		});
	}

	async order(largeScreen = false) {
		return this.router.navigateByUrl(largeScreen ? NAVIGATIONURLS.menu() : NAVIGATIONURLS.order());
	}

	async imprint() {
		await this.router.navigateByUrl(NAVIGATIONURLS.impressum());
	}

	async paymentSuccess(order: string, payment: string) {
		await this.router.navigate([NAVIGATIONURLS.paymentSuccessPaymentId(payment)], {
			queryParams: {
				order,
			},
		});
	}
	async maintenance() {
		await this.router.navigateByUrl(NAVIGATIONURLS.maintenance(), {
			skipLocationChange: true,
			replaceUrl: false,
		});
	}
	async tos() {
		await this.router.navigateByUrl(NAVIGATIONURLS.tos());
	}
	async signUp() {
		await this.router.navigateByUrl(NAVIGATIONURLS.signUp());
	}
	async signIn() {
		await this.router.navigateByUrl(NAVIGATIONURLS.signIn());
	}
	async myData() {
		await this.router.navigateByUrl(NAVIGATIONURLS.myData());
	}
	async faq() {
		await this.router.navigateByUrl(NAVIGATIONURLS.faq());
	}
	async myOrders() {
		await this.router.navigateByUrl(NAVIGATIONURLS.myOrders());
	}
	async account() {
		await this.router.navigateByUrl(NAVIGATIONURLS.account(), {
			replaceUrl: true,
		});
	}
	async privacy() {
		await this.router.navigateByUrl(NAVIGATIONURLS.privacy());
	}
	async emailConfirmation(userId: string = '') {
		await this.router.navigate([NAVIGATIONURLS.confirmEmail()], {
			replaceUrl: true,
			queryParams: {
				userId: userId,
			},
		});
	}
	async map(queryParams: Params = {}) {
		await this.router.navigate([NAVIGATIONURLS.map()], {
			queryParams,
			replaceUrl: true,
		});
	}
	async navigateToUrl(url: string) {
		await this.router.navigateByUrl(url);
	}
	async navigateToUrlWithParams(url: string, queryParams: Params) {
		await this.router.navigate([url], {
			queryParams,
			replaceUrl: true,
		});
	}
	async coupons() {
		await this.router.navigate([NAVIGATIONURLS.coupons()], {
			replaceUrl: true,
		});
		// await this.router.navigateByUrl(NAVIGATIONURLS.coupons());
	}
	async loyalty() {
		await this.router.navigate([NAVIGATIONURLS.loyalty()], {
			replaceUrl: true,
		});
	}
}
