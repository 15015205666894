import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import Preorder from 'src/smoothr-web-app-core/models/Preorder';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-change-preorder-type-dialog',
	templateUrl: './change-preorder-type-dialog.component.html',
	styleUrls: ['./change-preorder-type-dialog.component.scss'],
})
export class ChangePreorderTypeDialogComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController): Promise<any> {
		const modal = await modalCtrl.create({
			component: ChangePreorderTypeDialogComponent,
			componentProps: {},
			cssClass: 'allow-gps-modal auto-height',
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngOnInit() {}

	onPositiveClick() {
		this.modalCtrl.dismiss('take_away');
	}

	onNegativeClick() {
		this.modalCtrl.dismiss('address');
	}
	async dismiss() {
		await this.modalCtrl.dismiss();
	}
}
