import { HomePage } from 'src/app/pages/home/home.page';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-toolbar-logo',
	templateUrl: './toolbar-logo.component.html',
	styleUrls: ['./toolbar-logo.component.scss'],
})
export class ToolbarLogoComponent {
	environment = environment;
	constructor(
		private router: Router,
		public repository: RepositoryService
	) {}
	home() {
		HomePage.navigate(this.router, this.repository);
	}
}
