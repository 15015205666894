import { environment } from './../../../environments/environment';
import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderListAction } from '../../enums/OrderListAction';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {
	getPriceByOrder,
	// getPriceByOrder,
	numberToCurrency,
} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-order-list-pay',
	templateUrl: './order-list-pay.component.html',
	styleUrls: ['order-list-pay.component.scss'],
})
export class OrderListPayComponent implements DoCheck {
	@Output() action = new EventEmitter<OrderListAction>();
	@Input() editable = true;
	@Input() selected = false;
	@Input() selectedByOthers = false;
	OrderUtils = OrderUtils;
	isSomeOptionFullPrice = false;
	articlePrice: number;
	needsConfiguration = false;
	articleGroupPrice = 0;
	numberToCurrency = numberToCurrency;
	private prevPromoState = false;
	private prevQuantity = 1;
	environment = environment;
	constructor(private cd: ChangeDetectorRef) {}

	private _articleGroup: ArticleGroup;

	get articleGroup(): ArticleGroup {
		return this._articleGroup;
	}

	@Input()
	set articleGroup(value: ArticleGroup) {
		this._articleGroup = value;
		this.reloadArticle();
	}

	private _order: Order;
	get order(): Order {
		return this._order;
	}

	@Input()
	set order(value: Order) {
		this._order = value;
		this.reloadArticle();
	}

	priceOfOption(articleOption: ArticleOption): number {
		return getPriceByOrder(articleOption.article, this.order) * articleOption.quantity;
	}

	isOptionFullPrice(articleOption: ArticleOption): boolean {
		const group = this.articleGroup.article.groups.find(grp => grp._id === articleOption.group);
		return group && group.displayIdentifiers && group.displayIdentifiers.findIndex(di => DisplayIdentifier.fullPrice === di) >= 0;
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	reloadArticle() {
		this.needsConfiguration = false;
		this.articleGroupPrice = 0;
		this.prevPromoState = false;
		this.prevQuantity = 1;
		if (!this.articleGroup || !this.order) {
			this.articlePrice = 0;
			return;
		}
		this.prevQuantity = this.articleGroup.quantity;
		this.prevPromoState = this.articleGroup.isPromo;
		this.needsConfiguration = !ValidationUtils.areGroupsValid(this.articleGroup, []);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
			this.articlePrice = 0;
		} else {
			this.articlePrice = getPriceByOrder(this.articleGroup.article, this.order);
		}
		this.articleGroupPrice = OrderUtils.totalPriceByOrder(this.articleGroup, this.order);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
			this.articleGroupPrice -= OrderUtils.bogoPrice(this.articleGroup, this.order.type, this.order.preorder?.type);
		}
	}

	ngDoCheck(): void {
		if (this.prevPromoState !== this.articleGroup.isPromo) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
		if (this.prevQuantity !== this.articleGroup.quantity) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
	}

	addToPayment() {
		this.action.emit(OrderListAction.addtopay);
	}

	removeFromPayment() {
		this.action.emit(OrderListAction.removefrompay);
	}
}
