// const {name, version} = require('../../../package.json');

// export const apiEnvironment = {
// 	production: true,
// 	packageName: name,
// 	flavor: 'prodRelease',
// 	baseUrl: 'https://api.smoothr.de',
// 	version,
// 	VR_URL: 'https://vr-pay-ecommerce.de/v1/paymentWidgets.js',
// 	PAYPAL: {
// 		BN_CODE: 'SterlixGmbH_MP_Connected',
// 		JS_URL:
// 			'https://www.paypal.com/sdk/js?client-id=AYDMIg1C4j3uGnM7k0cZ2TJCkllONpdr8P9oTZaHJTsYx1vPWudzPgA_yTXF8BuLFtz5bRKFXRuxwKQg&integration-date=2020-09-04&components=buttons,funding-eligibility&currency='
// 	},
// 	GPAY: {
// 		GATEWAY: 'aciworldwide',
// 		MERCHANT_ID: 'BCR2DN6T5OYNT6CG'
// 	}
// };
const { name, version } = require('../../../package.json');

export const apiEnvironment = {
	production: false,
	packageName: name,
	flavor: 'devDebug',
	baseUrl: 'https://dev-api.smoothr.de',
	version,
	VR_URL: 'https://test.vr-pay-ecommerce.de/v1/paymentWidgets.js',
	PAYPAL: {
		BN_CODE: 'SterlixGmbH_MP_Connected',
		JS_URL:
			'https://www.paypal.com/sdk/js?client-id=Ac5vAqN4prOBX3YUFPX_L9Bmz3ncsdmOgkz7LuE2zP3yDbIaJB0y3SD4CTBdJqoHQhcWP6-WMklA-thJ&integration-date=2020-09-04&components=buttons,funding-eligibility&currency='
	},
	GPAY: {
		GATEWAY: 'aciworldwide',
		MERCHANT_ID: 'BCR2DN6T5OYNT6CG'
	}
};

import 'zone.js/dist/zone-error';
