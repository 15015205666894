import { Component, Input } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from 'src/environments/environment';
import { defaultsToArticleOption, getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { TranslateService } from '@ngx-translate/core';
import Localization from 'src/smoothr-web-app-core/models/Localization';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import { DisplayIdentifier } from 'src/smoothr-web-app-core/enums/DisplayIdentifier';
import { AppComponent } from 'src/app/app.component';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss'],
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	private _order: Order;
	priceText = '';
	get order(): Order {
		return this._order;
	}
	largeScreen = AppComponent.largeScreen;
	@Input()
	set order(value: Order) {
		this._order = value;
		this.reloadPrice();
	}

	numberToCurrency = numberToCurrency;

	constructor(private translate: TranslateService) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}
	@Input()
	categoryName: Localization;

	private minOfItem<T>(items: T[], transformer: (item: T) => number): T {
		if (items.length === 1) {
			return items[0];
		}
		const values = items.map(item => transformer(item));
		const min = Math.min(...values);
		const index = values.indexOf(min);
		return items[index];
	}

	private reloadPrice() {
		const articleGroup = new ArticleGroup();
		articleGroup.article = this.article;
		articleGroup.groups = defaultsToArticleOption(this.article, [], [], OrderType.PREORDER, this.order?.preorder?.type);

		articleGroup.quantity = 1;

		const article = articleGroup.article;
		let price = numberD(article.price);
		const processedGroups: string[] = [];
		article.groups.forEach(grp => {
			if (processedGroups.indexOf(grp._id) >= 0) return;
			if (ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, grp).times < 0) return;
			if (grp.requiredAmount == grp.limit) {
				const minimumItem = this.minOfItem(grp.articles, opt => numberD(opt.price));

				price += numberD(minimumItem.price) * grp.requiredAmount;
				const dependants = this.dependenciesRegardingOptionArticle(article, article.groups, minimumItem);
				dependants.forEach(dependant => {
					const dependantMinimumItem = this.minOfItem(dependant.articles, opt => numberD(opt.price));
					price += grp.requiredAmount * dependant.requiredAmount * numberD(dependantMinimumItem.price);
					processedGroups.push(dependant._id);
				});
				processedGroups.push(grp._id);
			}
		});

		this.price = price;
		if (!this.price) {
			return '';
		}
		const tagPrice = this.findTagPrice(this.article);
		if (tagPrice && tagPrice > 0) {
			this.price = this.price - tagPrice;
		}
		this.priceText = numberToCurrency(this.price, this.currency);
		return;
	}
	// if (this.article && this.order?.preorder?.type) {
	// 	const groups = this.test(articleGroup) as OptionGroup[];
	// 	if (this.findOptionGroupsMenu(groups)?.length > 0) {
	// 		const priceFinal = this.calculatePrice(
	// 			this.article,
	// 			this.article.groups
	// 		);
	// 		if (priceFinal > 0) {
	// 			this.price = priceFinal;
	// 			this.priceText = numberToCurrency(this.price, this.currency);

	// 			return;
	// 		}
	// 	}

	// 	if (groups.length > 0) {
	// 		this.calculationOldArticles(groups, articleGroup);
	// 	} else {
	// 		this.price = OrderUtils.articleGroupsTotalPrice(
	// 			[articleGroup],
	// 			OrderType.PREORDER,
	// 			this.order?.preorder?.type
	// 		);
	// 	}
	// } else {
	// 	this.price = OrderUtils.totalPrice(
	// 		articleGroup,
	// 		this.order?.type,
	// 		this.order?.preorder?.type
	// 	);
	// }
	// this.priceText = numberToCurrency(this.price, this.currency);
	// }
	calculationOldArticles(groups: OptionGroup[], articleGroup: ArticleGroup) {
		if (
			groups.length > 0 &&
			this.article?.tags?.length > 0 &&
			this.article.tags?.filter(it => it.identifier === 'fromPrice').length > 0
		) {
			const groupsPrice = groups
				.filter(it => it.requiredAmount > 0)
				.map(it => {
					return Math.min(...it.articles.map(article => numberD(article.price) * it.requiredAmount));
				});
			this.price = groupsPrice.reduce((a, b) => a + b, 0);
			this.priceText = numberToCurrency(this.price, this.currency);
			return;
		} else if (
			groups.length > 0 &&
			!!groups.find(it => {
				return it && it?.displayIdentifiers && !!it.displayIdentifiers.includes(DisplayIdentifier.menu);
			})
		) {
			const groupsDisplayIdentifiers = groups.filter(it => {
				return it?.displayIdentifiers && it?.displayIdentifiers.includes(DisplayIdentifier.menu);
			});

			if (groupsDisplayIdentifiers.length > 0) {
				const checkArrayOfGroups = []
					.concat(
						...groups
							.filter(it => {
								return it?.displayIdentifiers.includes(DisplayIdentifier.menu);
							})
							.map(it => it?.articles)
					)
					.filter(it => !it?.hidden)
					.filter(it => {
						let transformPrice = numberD(it?.price);
						return transformPrice !== 0 && Number(transformPrice);
					});
				const minPrice = Math.min(...checkArrayOfGroups.map(it => numberD(it?.price)));
				this.price = minPrice;

				this.priceText = numberToCurrency(this.price, this.currency);
				return;
			}
			this.price = OrderUtils.totalPrice(articleGroup, this.order?.type, this.order?.preorder?.type);
			this.priceText = numberToCurrency(this.price, this.currency);
		}
	}
	test(articleGroup: ArticleGroup) {
		let pages = [];
		for (const group of articleGroup.article.groups) {
			pages.push(group);
		}
		if (pages.length === 0) {
			pages.push([]);
		}
		if (pages.length > 1) {
			pages = pages.filter(page => page !== null);
		}
		const indicesOfRecommendations = [];
		pages.forEach((page, pageIndex) => {
			if (page.length > 0 && articleGroup.article.recommendations.find(recommendation => recommendation.group === page[0]._id)) {
				indicesOfRecommendations.push(pageIndex);
			}
		});
		return pages;
	}
	isTagPriceFrom() {
		return this.article && this.article?.tags?.length > 0 && this.article?.tags?.find(tag => tag?.identifier === 'fromPrice')
			? this.translate.instant('item_view.price_from')
			: '';
	}
	errorImage(image) {
		if (this.article.assets.original) image.src = this.article?.assets?.original;
		else '';
	}
	showMeasurement(article: Article) {
		if (article) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit && this._order && this._order.preorder) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					return (
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._order.preorder.type) * 10, this.currency)
					);
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					return (
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._order.preorder.type) * 10, this.currency)
					);
				}
				return (
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._order.preorder.type), this.currency)
				);
			}
		} else {
			return '';
		}
	}
	getArticleTags() {
		return Object.entries(this.article?.compound?.features || {})
			.filter(value => value[1] === true)
			.map(it => it[0])
			.filter(it => it === 'vegan' || it === 'vegetarian');
	}
	calculatePrice(article: Article, groups: OptionGroup[]) {
		const groupsMenu = this.findOptionGroupsMenu(groups);

		let articleListMenu = [].concat(...this.findOptionGroupsMenu(groups).map(it => it.articles));

		const resultPrice = Math.min(
			...articleListMenu.map(it => {
				const dependencies = this.dependenciesRegardingOptionArticle(this.article, groups, it).filter(it => it.requiredAmount > 0);

				if (dependencies.length == 0) {
					const test = groups.filter(opt => opt.requiredAmount > 0 && opt?.displayIdentifiers?.includes(DisplayIdentifier.sides));

					const result = test
						.map(dep => {
							const price =
								Math.min(
									...dep.articles
										.filter(art => numberD(art.price) && numberD(art.price) > 0)
										.map(art => {
											return numberD(art.price) * dep.requiredAmount;
										})
								) ?? 0;

							return price === Infinity ? 0 : price;
						})
						.reduce((acc, red) => {
							return acc + red;
						}, 0);
					return numberD(it.price) + result;
				} else {
					const result = dependencies
						.map(dep => {
							const price =
								Math.min(
									...dep.articles
										.filter(art => numberD(art.price) && numberD(art.price) > 0)
										.map(art => {
											return numberD(art.price) * dep.requiredAmount;
										})
								) ?? 0;

							return price === Infinity ? 0 : price;
						})
						.reduce((acc, red) => {
							return acc + red;
						}, 0);

					return numberD(it.price) + result;
				}
			})
		);

		if (resultPrice === 0 || articleListMenu.length === 0) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups = defaultsToArticleOption(
				this.article,
				[],
				this.article.defaults,
				OrderType.PREORDER,
				this.order?.preorder?.type
			);
			articleGroup.quantity = 1;
			const groupsDisplayIdentifiers = groups.filter(it => {
				return it?.displayIdentifiers && it?.displayIdentifiers?.includes(DisplayIdentifier.menu);
			});
			if (groupsDisplayIdentifiers.length > 0) {
				const checkArrayOfGroups = []
					.concat(
						...groups
							.filter(it => {
								return it?.displayIdentifiers?.includes(DisplayIdentifier.menu);
							})
							.map(it => it?.articles)
					)
					.filter(it => !it?.hidden)
					.filter(it => {
						const transformPrice = numberD(it?.price);
						return transformPrice !== 0; // && isNumber(transformPrice);
					});
				return Math.min(...checkArrayOfGroups.map(it => numberD(it?.price)));
			} else {
				return OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.order?.preorder?.type);
			}
		}
		return resultPrice;
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}
	findOptionGroupsMenu(option: OptionGroup[]) {
		return option && option.length > 0
			? option.filter(
					it =>
						it.displayIdentifiers && it.displayIdentifiers.length > 0 && it.displayIdentifiers.includes(DisplayIdentifier.menu)
			  )
			: [];
	}
	calculatePfand(article: Article) {
		if (article && article?.nutritions?.portionInG) {
			return Math.round((this.price * 1000) / numberD(article.nutritions.portionInG));
		}
		return 0;
	}
	calculateDeposit(article: Article) {
		if (numberD(article.deposit)) {
			let plusTextDeposit = '';
			if (article.nutritions.portionInG) {
				plusTextDeposit =
					'1.0 l -' +
					numberToCurrency(Math.round((this.price * 1000) / numberD(article.nutritions.portionInG)), this.order.currency) +
					'/liter';
			}
			return ' - inkl. Pfand (' + numberToCurrency(numberD(article.deposit), this.order.currency) + ')' + ' - ' + plusTextDeposit;
		}
		return '';
	}

	getArticlePrice(article: Article) {
		let totalPrice = getPrice(article, OrderType.PREORDER, this._order?.preorder?.type);
		const tagPrice = this.findTagPrice(this.article);
		console.log(totalPrice, tagPrice);

		if (tagPrice && tagPrice > 0) {
			totalPrice = totalPrice - tagPrice;
		}
		return totalPrice;
	}
	findTagPrice(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			const foundTag = article?.tags?.find(it => it?.identifier === 'deposit_25' || it?.identifier === 'deposit_15');
			if (foundTag) {
				return foundTag.identifier === 'deposit_25' ? 0.25 : foundTag.identifier === 'deposit_15' ? 0.15 : 0;
			}
		} else {
			return null;
		}
	}
}
