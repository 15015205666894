import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { getAvailability, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-flavor',
	templateUrl: './article-option-group-flavor.component.html',
	styleUrls: ['article-option-group-flavor.component.scss'],
})
export class ArticleOptionGroupFlavorComponent implements OnInit {
	@Input() optionGroup: OptionGroup;
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	di = DisplayIdentifier;
	pricesMap = new Map<string, number>();

	constructor() {}

	private _selected: ArticleOption[] = [];

	get selected(): ArticleOption[] {
		return this._selected;
	}

	@Input()
	set selected(value: ArticleOption[]) {
		this._selected = value;
	}

	ngOnInit() {}

	onAdd(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	onRemove(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = -1;
		this.selection.emit(articleOption);
	}

	count(article: Article) {
		return this.selected.filter(ao => ao.article._id === article._id).reduce((prev, curr) => prev + curr.quantity, 0);
	}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}
	price(option: Article): string {
		this.likeSotCalulation(option);
		if (this.pricesMap.has(option._id)) {
			const minValue = Math.min(...this.pricesMap.values());
			if (this.pricesMap.get(option._id) > minValue) {
				const diffPrice = this.pricesMap.get(option._id) - minValue;
				return '+ ' + numberToCurrency(diffPrice, this.currency);
			} else {
				return minValue ? numberToCurrency(minValue, this.currency) : '';
			}
		}
		if (this.optionGroup.displayIdentifiers.includes(DisplayIdentifier.sides)) {
			const minValue = Math.min(...this.optionGroup.articles.map(it => numberD(it.price)));
			const diffTo = numberD(option.price) - minValue;
			if (diffTo > 0) {
				return numberToCurrency(diffTo, this.currency);
			}
			return '';
		}
		let price = getPrice(option, OrderType.PREORDER, this.preorderType);
		return price > 0 ? numberToCurrency(price, this.currency) : '';
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}
	likeSotCalulation(option: Article) {
		// if (this.optionGroup.name.de.includes('Einzel oder Menü? SPLIT')) {
		if (this.optionGroup.displayIdentifiers.includes(DisplayIdentifier.menu)) {
			this.optionGroup.articles.forEach(it => {
				const price = (this.dependenciesRegardingOptionArticle(this.article, this.article.groups, it) ?? [])
					.map(option => {
						const arrayOfArticlesPriceInOption = option.articles.map(art => {
							return numberD(art.price);
						});
						const result = Math.min(...arrayOfArticlesPriceInOption) * option.requiredAmount;

						return result === Infinity || !result ? 0 : result;
					})
					.reduce((a, b) => a + b, 0);

				this.pricesMap.set(it._id, price);

				if (this.pricesMap.get(it._id) == null || this.pricesMap.get(it._id) == 0) {
					this.pricesMap.set(it._id, numberD(it.price) === Infinity ? 0 : numberD(it.price));
				}
			});
		}
	}
}
