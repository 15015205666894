import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: '[openClassOnHover]',
})
export class OpenClassOnHoverDirective {
	@Input('openClassOnHover') className: string;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) {}

	@HostListener('mouseenter') onMouseEnter() {
		const child = this.el.nativeElement.querySelector(`.${this.className}`);
		if (child) {
			this.renderer.addClass(child, 'open');
		}
	}

	@HostListener('mouseleave') onMouseLeave() {
		const child = this.el.nativeElement.querySelector(`.${this.className}`);
		if (child) {
			this.renderer.removeClass(child, 'open');
		}
	}
}
