import { SignInPage } from './../sign-in/sign-in.page';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import validator from 'validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountPage } from '../account/account.page';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Gender } from 'src/smoothr-web-app-core/enums/Gender';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { AuthStrategy } from 'src/smoothr-web-app-core/models/AuthStrategy';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { IonInput, ModalController } from '@ionic/angular';

import { NavigationService } from 'src/app/services/navigation.service';
import { EmailConfirmationPage } from '../email-confirmation/email-confirmation.page';
import moment, { Moment } from 'moment';
import { InfoModalComponent } from 'src/app/components/info-modal/info-modal.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { MapsUtils, calculateGeoDistance } from 'src/app/utils/maps';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import Geocoder = google.maps.Geocoder;
import LatLng = google.maps.LatLng;
import { venueAcceptsOrders } from 'src/smoothr-web-app-core/utils/utils';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['sign-up.page.scss'],
})
export class SignUpPage implements OnDestroy, OnInit {
	static url = 'sign-up';
	static my_data = 'account-data';
	private onDestroy$ = new Subject();
	showRePassword = false;
	showPassword = false;
	loading = false;
	isAccount = false;
	venues: Venue[] = [];
	isEditableVenueSelection = false;
	Gender = Gender;
	gender = 'gender';
	standort_der_registerung = 'standort_der_registerung';

	name = 'name';
	vorname = 'vorname';
	email = 'email';
	agbChecked = 'agbChecked';
	password = 'password';
	rePassword = 'rePassword';
	day = 'day';
	month = 'month';
	year = 'year';

	public loading$ = new BehaviorSubject(false);
	public signUpForm: FormGroup;

	constructor(
		public translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private navService: NavigationService,
		private fb: FormBuilder,
		private snackbarCtrl: MatSnackBar,
		private modalCtrl: ModalController,
		private geolocation: Geolocation
	) {}
	static navigate(router: Router) {
		router.navigateByUrl(SignUpPage.url);
	}
	static async navigateMyData(router: Router) {
		await router.navigateByUrl(SignUpPage.my_data);
	}
	ngOnDestroy() {
		this.onDestroy$.next();
	}

	ngOnInit() {
		this.isAccount = this.router.url.includes('account-data');
		this.createFormSignUp();
		if (this.isAccount && this.repository.customer) {
			this.signUpForm.patchValue({
				gender: this.repository.customer.gender,
				name: this.repository.customer.name?.split(' ')[0] ?? '',
				vorname: this.repository.customer.name?.split(' ')[1] ?? '',
				email: this.repository.customer.email,
				day: new Date(this.repository.customer.birthDate).getDate(),
				month: new Date(this.repository.customer.birthDate).getMonth() + 1,
				year: new Date(this.repository.customer.birthDate).getFullYear(),
			});
		}
		this.signUpForm.valueChanges.subscribe(v => console.log(v, this.signUpForm.valid, this.signUpForm.invalid));
	}

	createFormSignUp() {
		this.isEditableVenueSelection = false;
		const formControlNames: any = {};

		formControlNames[this.gender] = ['', [Validators.required]];
		if (!this.isAccount) {
			formControlNames[this.standort_der_registerung] = [null, [Validators.required]];
		}
		formControlNames[this.name] = ['', [Validators.required]];
		formControlNames[this.vorname] = ['', [Validators.required]];
		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				),
			],
		];

		if (!this.isAccount) {
			formControlNames[this.agbChecked] = [false, [Validators.required, Validators.requiredTrue]];

			formControlNames[this.password] = [
				'',
				[Validators.required, Validators.minLength(8), Validators.pattern(/\d+/), Validators.pattern(/[A-Z]+/)],
			];

			formControlNames[this.rePassword] = ['', [Validators.required, this._isPasswordMatch()]];
		}

		formControlNames[this.day] = ['', [Validators.max(31)]];
		formControlNames[this.month] = ['', [Validators.max(12)]];
		formControlNames[this.year] = ['', [Validators.min(1900), Validators.max(2023)]];

		this.signUpForm = this.fb.group(formControlNames);
		this.loadAllVenue();
	}
	private async getLocation() {
		try {
			let options = {
				timeout: 10000,
				enableHighAccuracy: true,
				maximumAge: 3600,
			};

			const geoposition = await this.geolocation.getCurrentPosition(options);
			const result = await new Geocoder().geocode({
				location: new LatLng(geoposition.coords.latitude, geoposition.coords.longitude),
			});

			if (result.results.length > 0) {
				console.log(result, MapsUtils.placeToAddressGoogle(result.results[0]));
				return MapsUtils.placeToAddressGoogle(result.results[0]);
			} else {
				return null;
			}
		} catch (e) {
			console.log(e);
			return null;
		}
	}
	private async loadAllVenue() {
		try {
			if (!this.isAccount) {
				this.venues = (await this.repository.getAllVenues()).filter(
					it => venueAcceptsOrders(it, PreorderType.TAKE_AWAY) || venueAcceptsOrders(it, PreorderType.DELIVERY)
				);
				const address = await this.getLocation();
				this.venues = this.venues.sort((a, b) => {
					const distanceA = calculateGeoDistance(address.lat, address.lng, a.location.coordinates[1], a.location.coordinates[0]);
					const distanceB = calculateGeoDistance(address.lat, address.lng, b.location.coordinates[1], b.location.coordinates[0]);
					return distanceB - distanceA;
				});
				if (this.venues.length > 0 && address) {
					this.signUpForm.patchValue({
						standort_der_registerung: this.venues[0]._id,
					});
					this.isEditableVenueSelection = true;
				}
			}
		} catch (e) {
			console.log(e);
		}
	}
	private _isPasswordMatch(): ValidatorFn {
		return () => {
			const pass = this.signUpForm?.get('password')?.value;
			const confirmPass = this.signUpForm?.get('rePassword')?.value;
			return pass === confirmPass ? null : { notSame: true };
		};
	}

	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.hasError('required') && this.signUpForm.get(controlName)?.touched;
		}

		return this.signUpForm.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.errors?.pattern && !this.signUpForm.get(controlName)?.hasError('required');
		}
		return this.signUpForm.invalid;
	}

	getControlValue(controlName?: string) {
		if (controlName) {
			return this.signUpForm?.get(controlName)?.value;
		}
		return '';
	}

	async signUp() {
		this.signUpForm.markAllAsTouched();
		if (this.repository.customer.isAnonymous) {
			this.loading$.next(true);
			try {
				const data = {
					name: this.getControlValue(this.name) + ' ' + this.getControlValue(this.vorname),
					gender: this.getControlValue(this.gender),
					email: this.getControlValue(this.email),
					preferredVenues: [],
					registerInPiggy: true,
					isMobile: false,
					preferredVenue: this.getControlValue(this.standort_der_registerung),
				} as any;
				if (
					this.getControlValue(this.year) != '' &&
					this.getControlValue(this.month) != '' &&
					this.getControlValue(this.day) != ''
				) {
					data.birthDate = new Date(
						this.getControlValue(this.year),
						this.getControlValue(this.month) - 1,
						this.getControlValue(this.day),
						6,
						0,
						0,
						0
					);
				}

				await Api.signUpWithCredentials(data, this.getControlValue('password'));
				this.loading$.next(false);
				await EmailConfirmationPage.navigate(this.router, true);
			} catch (e) {
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.signUpForm.controls['email'].setErrors({
						UserAlreadyExistsError: true,
					});
				}
				this.loading$.next(false);
			}
		}
	}

	signIn() {
		SignInPage.navigate(this.router);
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				const result = await Api.signIn(AuthStrategy.GOOGLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(AuthStrategy.FACEBOOK);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(AuthStrategy.APPLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	openTerms() {
		window.open('/tos', '_blank');
	}

	openPrivacy() {
		window.open('/privacy', '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			this.navService.home();
		} else {
			this.navService.menu();
		}
	}

	gotoNextField(nextElement: IonInput) {
		nextElement.setFocus();
	}

	async updateCustomer() {
		try {
			const response = await Api.patchCustomer({
				...this.repository.customer,
				name: this.getControlValue(this.name) + ' ' + this.getControlValue(this.vorname),
				gender: this.getControlValue(this.gender),
				email: this.getControlValue(this.email),
				preferredVenues: [],
				birthDate:
					this.getControlValue(this.year) && this.getControlValue(this.month) && this.getControlValue(this.day)
						? new Date(
								new Date(0).setFullYear(
									this.getControlValue(this.year),
									Number(this.getControlValue(this.month)) - 1,
									this.getControlValue(this.day)
								)
						  ).toString()
						: '',
			});
			this.snackbarCtrl.open(this.translate.instant('account.success'), null, {
				duration: 2000,
			});
			this.repository._customerAuth.customer = response.data;
			this.repository.customerAuth.next(this.repository._customerAuth);
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('account.error'), null, {
				duration: 2000,
			});
		}
	}

	async back() {
		await this.navService.signIn();
	}
	async deleteAccount() {
		const result = await InfoModalComponent.show(this.modalCtrl, {
			title: this.translate.instant('account.delete_account.header'),
			info: this.translate.instant('account.delete_account.msg'),
			mainButton: this.translate.instant('account.delete_account.yes'),
			closeButton: this.translate.instant('account.delete_account.no'),
		});
		if (result && this.repository?._customerAuth && this.repository?._customerAuth?.customer) {
			try {
				await Api.deleteAccount(this.repository?._customerAuth?.customer?._id);
				this.snackbarCtrl.open(this.translate.instant('account.delete_account.success'), null, {
					duration: 3000,
				});
				this.repository.customerAuth.next(null);
				await this.navService.home();
			} catch (e) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('account.delete_account.error'), null, {
					duration: 3000,
				});
			}
		}
	}
}
